import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useGetDocumentsListByProjectQuery,
  useUnarchiveProjectMutation,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import dayjs from 'dayjs'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { useNavigate } from 'react-router-dom'
import {
  useGetProjectRiskStatsQuery,
  useGetProjectRisksProcessingStatusQuery,
} from '../../redux/api/project-risk-api-slice'
import { RiskRanking } from '../../shared/interfaces/project/risk/risk-inteface'
import {
  CheckCircleIcon,
  ClockIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid'

const ProjectUploadStatus: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const [timeEstimate, setTimeEstimate] = useState<number | null>(100)
  const documentProcessingRef = useRef<{ [documentId: string]: boolean }>({})
  const navigate = useNavigate()
  const [unarchiveProject] = useUnarchiveProjectMutation()

  const { currentData: projectRiskStats, refetch: projectRiskRefetch } =
    useGetProjectRiskStatsQuery(currentProject?.uuid ?? skipToken)

  const { currentData: riskPipelineStats } =
    useGetProjectRisksProcessingStatusQuery(currentProject?.uuid ?? skipToken, {
      pollingInterval: 2000,
    })

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: 5000,
    }
  )

  useEffect(() => {
    if (!documents) {
      return
    }
    projectRiskRefetch()
  }, [documents, currentProject, projectRiskRefetch])

  useEffect(() => {
    if (!documents) {
      return
    }
    if (
      documents?.every(
        (document) =>
          document.job_status !== 'PENDING' &&
          document.job_status !== 'PROCESSING' &&
          document.job_status !== 'ARCHIVED' &&
          document.job_status !== 'UNARCHIVING'
      )
    ) {
      setTimeEstimate(null)
    }
    const processingDocuments = documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    )
    if (documents.find((document) => document.total_pages === null)) {
      setTimeEstimate(null)
      return
    }
    const newDocumentsToAdd: ProjectDocumentMetadata[] = []
    for (const processingDocument of processingDocuments) {
      if (!documentProcessingRef.current[processingDocument.id]) {
        newDocumentsToAdd.push(processingDocument)
        documentProcessingRef.current[processingDocument.id] = true
      }
    }
    if (newDocumentsToAdd.length === 0) {
      return
    }
    setTimeEstimate(
      (t) =>
        (t ?? 0) +
        newDocumentsToAdd.reduce((acc, cur) => {
          const difference = dayjs().diff(dayjs(cur.date_created), 'second')
          const estimate = Math.round(acc + cur.total_pages) - difference
          if (estimate < 0) {
            return 0
          }
          return estimate
        }, 0)
    )
  }, [documents])

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  const fancyTimeFormat = (duration: number) => {
    if (duration === 0) {
      return 'Almost there...'
    }
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ''

    if (hrs > 0) {
      ret += `${hrs}:${mins < 10 ? '0' : ''}`
    }

    ret += `${mins}:${secs < 10 ? '0' : ''}`
    ret += `${secs}`

    return ret
  }

  useEffect(() => {
    if ((timeEstimate ?? 0) < 0) {
      setTimeEstimate(0)
      return
    }
    if (!timeEstimate) return

    const intervalId = setInterval(() => {
      setTimeEstimate(timeEstimate - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeEstimate])

  const highestRisk = useMemo(() => {
    if (!projectRiskStats || projectRiskStats.length === 0) {
      return null
    }
    return projectRiskStats.reduce<RiskRanking>((acc, cur) => {
      if (!cur?.risk_ranking) {
        return acc
      }
      if (cur.risk_ranking > acc) {
        return cur.risk_ranking
      }
      return acc
    }, projectRiskStats[0].risk_ranking ?? 1)
  }, [projectRiskStats])

  const navigateRisks = useCallback(() => {
    navigate(
      `/${currentProject?.uuid}/riskreview${
        currentDocument ? `/${currentDocument?.uuid}` : ''
      }`
    )
  }, [navigate, currentProject, currentDocument])

  const getRiskRank = useMemo(() => {
    switch (highestRisk) {
      case RiskRanking.High:
        return 'High'
      case RiskRanking.Medium:
        return 'Medium'
      case RiskRanking.Low:
        return 'Low'
      default:
        return ''
    }
  }, [highestRisk])

  const getHighestRiskCount = useMemo(() => {
    if (!projectRiskStats) {
      return 0
    }
    return projectRiskStats.find((risk) => risk.risk_ranking === highestRisk)
      ?.count
  }, [highestRisk, projectRiskStats])

  const shouldDisplay = useMemo(() => {
    return (
      (riskPipelineStats?.count ?? 0) > 0 ||
      (projectRiskStats?.length ?? 0) > 0 ||
      documentProcessingCount > 0
    )
  }, [
    riskPipelineStats?.count,
    projectRiskStats?.length,
    documentProcessingCount,
  ])

  const hasArchivedDocument = useMemo(() => {
    return documents?.some(
      (d) => d.job_status === 'ARCHIVED' || d.job_status === 'UNARCHIVING'
    )
  }, [documents])

  useEffect(() => {
    const hasArchived = documents?.find((d) => d.job_status === 'ARCHIVED')
    if (hasArchived && currentProject) {
      unarchiveProject(currentProject)
    }
  }, [currentProject, unarchiveProject, documents])

  const risksAreAnalysing = useMemo(() => {
    return (riskPipelineStats?.count ?? 0) > 0
  }, [riskPipelineStats?.count])

  const getStatusDescription = useMemo(() => {
    if (hasArchivedDocument) {
      return (
        <div className="flex w-full bg-blue-50 p-2">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Your documents are being unarchived, this will take a few minutes.
            </p>
          </div>
        </div>
      )
    }
    if (documentProcessingCount > 0) {
      return (
        <div className="flex w-full bg-blue-50 p-2">
          <div className="flex-shrink-0">
            <ClockIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Provision AI is analysing your documents
            </p>
            <p className="flex text-sm text-blue-700">
              {timeEstimate !== null ? (
                <div className={`block text-left tabular-nums`}>
                  Estimated time: {fancyTimeFormat(timeEstimate)}
                </div>
              ) : (
                <span>Calculating...</span>
              )}
            </p>
          </div>
        </div>
      )
    }
    if (projectRiskStats?.length && !risksAreAnalysing) {
      return (
        <>
          <div className="w-full bg-green-50 p-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">
                  Project Analysis Complete: {getHighestRiskCount} {getRiskRank}{' '}
                  risk(s)
                </p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    onClick={navigateRisks}
                    className="inline-flex items-center gap-2 rounded-md bg-green-50 p-1.5 text-sm text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  >
                    Go to Risk Review
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    if (risksAreAnalysing) {
      return (
        <>
          <div className="flex w-full bg-blue-50 p-2">
            <div className="flex-shrink-0">
              <ClockIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                Risks analysing: {riskPipelineStats?.count} risk(s) are being
                analysed
              </p>
            </div>
          </div>
        </>
      )
    }
    return null
  }, [
    documentProcessingCount,
    getHighestRiskCount,
    getRiskRank,
    hasArchivedDocument,
    navigateRisks,
    projectRiskStats?.length,
    riskPipelineStats?.count,
    risksAreAnalysing,
    timeEstimate,
  ])

  return shouldDisplay ? <>{getStatusDescription}</> : null
}

export default ProjectUploadStatus
