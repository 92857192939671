import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { useBackButton } from '../../hooks/use-back-button'
import { selectCurrentDocument } from '../../redux/application-slice'

interface SidebarTOCButtonProps {
  toc
  currentPage: number
}

const SidebarTOCButton: React.FC<SidebarTOCButtonProps> = ({
  toc,
  currentPage,
}) => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const currentDocument = useSelector(selectCurrentDocument)
  const onTOCClick = useCallback(() => {
    const page = documentViewerContext?.documentViewer?.getCurrentPage()
    if (!page || !currentDocument?.uuid) {
      return null
    }
    addToNavigationHistoryAndNavigate(
      'documents',
      'documents',
      currentDocument?.uuid,
      toc.page
    )
  }, [
    addToNavigationHistoryAndNavigate,
    currentDocument?.uuid,
    documentViewerContext?.documentViewer,
    toc.page,
  ])
  return (
    <button
      key={`${toc.title}${toc.page}`}
      className={`flex w-full cursor-pointer justify-between space-x-4 px-2 py-1 hover:bg-gray-200 ${
        currentPage === toc.page ? 'bg-gray-200' : ''
      }`}
      onClick={onTOCClick}
    >
      <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>
        {toc.title}
      </div>
      <div className={'flex-shrink-0'}>
        <span>{toc.page}</span>
      </div>
    </button>
  )
}

export default SidebarTOCButton
