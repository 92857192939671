import React, { useMemo } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../../redux/workflow-slice'

export type RiskSort = 'Risk Ranking' | 'A-Z' | 'Z-A'

const sorts: RiskSort[] = ['Risk Ranking', 'A-Z', 'Z-A']

const RiskReviewSortListBox: React.FC = () => {
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)
  const dispatch = useDispatch()
  const setSelectedSort = useMemo(() => {
    return (value: RiskSort) => {
      dispatch(
        setRiskWorkflowFilterState({ ...riskWorkflowFilterState, sort: value })
      )
    }
  }, [dispatch, riskWorkflowFilterState])
  return (
    <Listbox value={riskWorkflowFilterState.sort} onChange={setSelectedSort}>
      <div className={'relative flex self-stretch'}>
        <Listbox.Button
          aria-label="Document Listbox Button"
          className="flex-shrink-0 border-gray-300 bg-white px-2.5 py-1 pr-7 border rounded w-full text-gray-900 text-left text-sm truncate"
        >
          {riskWorkflowFilterState.sort ?? 'Sort'}
          <span className="right-0 absolute inset-y-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="top-full right-0 z-10 absolute bg-white ring-opacity-5 mt-1 rounded-md max-h-96 text-base sm:text-sm overflow-auto ring-1 ring-black focus:outline-none">
          <Listbox.Option
            aria-label={'Select All Sorts'}
            className={
              'flex cursor-pointer justify-between truncate px-2 py-2 hover:bg-gray-200'
            }
            value={null}
            key={'null'}
          >
            All Risks
          </Listbox.Option>
          {sorts.map((sort) => (
            <Listbox.Option
              aria-label={`Select ${sort}`}
              key={`listbox_sort_option_${sort}`}
              value={sort}
              className={
                'flex cursor-pointer justify-between truncate px-2 py-2 hover:bg-gray-200'
              }
            >
              {sort}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default RiskReviewSortListBox
