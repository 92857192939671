import React, { useCallback, useMemo } from 'react'
import { selectCurrentProject } from '../../../redux/application-slice'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import CommentTableCard from './comment-table-card'
import CommentLoadingCard from './comment-loading-card'
import CommentTableHeader from './comment-table-header'
import { Tooltip } from 'react-tooltip'
import DuplicatorStatus from '../../duplicator/duplicator-status'
import {
  selectCommentWorkflowFilterState,
  selectCommentWorkflowFilterStateQueryParams,
} from '../../../redux/workflow-slice'
import { useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetRevisionsQuery } from '../../../redux/api/api-revisions-slice'

function CommentTablePage() {
  const currentProject = useSelector(selectCurrentProject)
  const revisionQueryParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )
  const commentWorkflowFilterState = useSelector(
    selectCommentWorkflowFilterState
  )
  const { data: revisionsData, isLoading: revisionsLoading } =
    useGetRevisionsQuery(
      revisionQueryParams?.documentIds?.length || revisionQueryParams?.projectId
        ? revisionQueryParams
        : skipToken
    )

  const filterRevisionsByQuery = useCallback(
    (revisions: Revision[]) => {
      if (!commentWorkflowFilterState.searchQuery) {
        return revisions
      }
      return revisions.filter(
        (r) =>
          r.document_title
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.original_text
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.v2_text
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.document_title
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.revised_text
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.section_reference
            ?.toLowerCase()
            .includes(commentWorkflowFilterState.searchQuery.toLowerCase()) ||
          r.comments?.some(
            (c) =>
              c?.comment
                ?.toLowerCase()
                .includes(
                  commentWorkflowFilterState.searchQuery.toLowerCase()
                ) ||
              c?.user?.first_name
                ?.toLowerCase()
                .includes(
                  commentWorkflowFilterState.searchQuery.toLowerCase()
                ) ||
              c?.user?.last_name
                ?.toLowerCase()
                .includes(commentWorkflowFilterState.searchQuery.toLowerCase())
          )
      )
    },
    [commentWorkflowFilterState.searchQuery]
  )

  const filterRevisionsByStatus = useCallback(
    (revisions: Revision[]) => {
      if (!commentWorkflowFilterState.statusFilter.length) {
        return revisions
      }
      return revisions.filter((r) =>
        commentWorkflowFilterState.statusFilter.includes(r.revision_status)
      )
    },
    [commentWorkflowFilterState.statusFilter]
  )

  const filterRevisionsByAuthors = useCallback(
    (revisions: Revision[]) => {
      if (!commentWorkflowFilterState.authorsFilter.length) {
        return revisions
      }
      return revisions.filter((r) =>
        commentWorkflowFilterState.authorsFilter.some(
          ({ id }) => r.user_created?.id === id
        )
      )
    },
    [commentWorkflowFilterState.authorsFilter]
  )

  const filterRevisionsByLatestVersion = useCallback(
    (revisions: Revision[]) => {
      return revisions.filter(
        (r) => !revisions.some((rev) => rev.v1_revision === r.id)
      )
    },
    []
  )

  const revisionsToDisplay: Revision[] = useMemo(() => {
    if (!revisionsData) {
      return []
    }

    let revisions: Revision[] = []

    revisions = revisionsData
    revisions = filterRevisionsByLatestVersion(revisions)
    revisions = filterRevisionsByStatus(revisions)
    revisions = filterRevisionsByAuthors(revisions)
    revisions = filterRevisionsByQuery(revisions)
    return revisions
  }, [
    revisionsData,
    filterRevisionsByLatestVersion,
    filterRevisionsByStatus,
    filterRevisionsByAuthors,
    filterRevisionsByQuery,
  ])

  const hasDuplicatedComments = useMemo(() => {
    return Boolean(revisionsData?.some((r) => r.v1_revision !== null))
  }, [revisionsData])

  const getRevisionVersion = useCallback((revision: Revision) => {
    if (!revision.previous_revision) {
      return 1
    }
    let version = 1
    let revisionPointer: Revision | undefined = revision.previous_revision

    while (revisionPointer) {
      version++
      revisionPointer = revisionPointer.previous_revision
    }
    return version
  }, [])
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <CommentTableHeader revisionsData={revisionsData ?? []} />
      <DuplicatorStatus revisionParams={revisionQueryParams} />
      <div className="flex-grow space-y-2 overflow-auto bg-gray-50 p-2">
        {!revisionsLoading && currentProject ? (
          revisionsToDisplay.length > 0 ? (
            revisionsToDisplay.map((revision) => {
              return (
                <CommentTableCard
                  key={`comment_card_${revision.id}`}
                  revision={revision}
                  hasDuplicatedComments={hasDuplicatedComments}
                  revisionVersion={getRevisionVersion(revision)}
                />
              )
            })
          ) : (
            <div className="text-center text-sm text-gray-700">
              No comments to show
            </div>
          )
        ) : (
          <div>
            {Array(6)
              .fill(0)
              .map((_, index) => index)
              .map((loadingCard) => (
                <CommentLoadingCard
                  key={`loading_commment_card_${loadingCard}`}
                />
              ))}
          </div>
        )}
      </div>
      <Tooltip
        id={'comment-badge-tooltip'}
        style={{ zIndex: 100, fontSize: '0.75rem' }}
        className="text-xs"
      />
    </div>
  )
}

export default CommentTablePage
