import React, { useCallback, useEffect } from 'react'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import { useDispatch, useSelector } from 'react-redux'
import { selectCCDCVersion, selectNewAmendment } from '../../redux/editor-slice'
import { setTemporaryHighlight } from '../../redux/viewer-slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { selectCurrentProject } from '../../redux/application-slice'
import { isRatio } from '../../utils/convert-quads'
import { CheckIcon } from '@heroicons/react/20/solid'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

interface SupplementaryConditionsQACardProps {
  documentChange: DocumentChange
  selectedDocumentChange: DocumentChange | null
  setSelectedDocumentChange: (dc: DocumentChange | null) => void
}

const SupplementaryConditionsQACard: React.FC<
  SupplementaryConditionsQACardProps
> = ({ documentChange, selectedDocumentChange, setSelectedDocumentChange }) => {
  const ccdcVersion = useSelector(selectCCDCVersion)
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const newAmendment = useSelector(selectNewAmendment) as DocumentChange

  useEffect(() => {
    if (!selectedDocumentChange && !newAmendment) {
      return
    }
    const element = document.getElementById(
      `document_change_${selectedDocumentChange?.id ?? 'new_amendment'}`
    )
    if (!element) {
      return
    }
  }, [selectedDocumentChange, newAmendment])

  const setHighlight = useCallback(
    (
      documentChange: DocumentChange,
      source: { source_document_uuid: string | undefined; source: number }
    ) => {
      navigate(
        `/${currentProject?.uuid}/quality-assurance/${source.source_document_uuid}`
      )
      const page = source.source.toString()
      searchParams.set('page', page)
      setSearchParams(searchParams)
      const highlightID = uuidv4()
      const quads = documentChange?.source?.quads
      dispatch(
        setTemporaryHighlight([
          {
            page: parseInt(source.source.toString()),
            quads,
            id: highlightID,
            pdfCoords: isRatio(quads),
          },
        ])
      )
      setTimeout(() => {
        const el = document?.getElementById(highlightID)
        if (!el) {
          return
        }
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 500)
    },
    [currentProject?.uuid, dispatch, navigate, searchParams, setSearchParams]
  )

  const onClickDocumentChange = useCallback(() => {
    setSelectedDocumentChange({ ...documentChange })
    setHighlight(documentChange, {
      source: documentChange?.source?.page ?? 1,
      source_document_uuid: documentChange?.source?.document_uuid,
    })
  }, [documentChange, setSelectedDocumentChange, setHighlight])

  const getBorderColor = (changeType) => {
    switch (changeType) {
      case 'create':
        return 'border-l-green-500'
      case 'delete':
        return 'border-l-red-500'
      case 'amend':
        return 'border-l-yellow-300'
      case 'replace':
        return 'border-l-orange-300'
      default:
        return 'border-l-gray-500'
    }
  }

  return (
    <button
      id={`document_change_${documentChange?.id ?? 'new_amendment'}`}
      className={`flex w-[150px] cursor-pointer items-center justify-between space-x-1 overflow-hidden rounded border border-l-4 ${getBorderColor(
        documentChange.change_type
      )} p-2 ${
        selectedDocumentChange?.id === documentChange?.id
          ? 'bg-gray-100'
          : 'bg-white'
      }`}
      onClick={onClickDocumentChange}
      disabled={!ccdcVersion || ccdcVersion === 'Select Version'}
    >
      <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>
        {documentChange?.original_clause_reference}
      </div>
      {documentChange.qa_checked ? (
        <div>
          <CheckIcon width={18} />
        </div>
      ) : (
        <div>
          <QuestionMarkCircleIcon width={18} />
        </div>
      )}
    </button>
  )
}

export default SupplementaryConditionsQACard
