import React, { useEffect } from 'react'
import TypesenseSearch from './sidebar/typesense-search'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectDocumentViewerSidebarOpen,
  setDocumentViewerSidebarOpen,
} from '../redux/viewer-slice'
import SidebarToc from './sidebar/sidebar-toc'
import SidebarDefinitions from './sidebar/sidebar-definitions'
import { useParams } from 'react-router-dom'

const DocumentViewerSidebar: React.FC = () => {
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const dispatch = useDispatch()
  const { documentId } = useParams<{ documentId: string }>()

  useEffect(() => {
    if (!documentId) {
      dispatch(setDocumentViewerSidebarOpen(null))
    }
  }, [documentId, dispatch])
  return (
    <div
      className={`absolute right-full top-10 z-[39] min-h-32 w-96 border-l bg-gray-50 pb-1 shadow-lg ${
        documentViewerSidebarOpen === 'search'
          ? 'overflow-visible rounded'
          : 'overflow-auto'
      }`}
      style={{
        maxHeight:
          documentViewerSidebarOpen === 'search' ? '' : 'calc(100vh - 5rem)',
      }}
    >
      {documentViewerSidebarOpen === 'search' && <TypesenseSearch />}
      {documentViewerSidebarOpen === 'toc' && <SidebarToc />}
      {documentViewerSidebarOpen === 'definitions' && <SidebarDefinitions />}
    </div>
  )
}

export default DocumentViewerSidebar
