import React, { useCallback, useMemo } from 'react'
import DocumentListbox from '../../document-listbox/document-listbox'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { useSearchParams } from 'react-router-dom'
import UseUrlNavigate from '../../../hooks/use-url-navigate'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useSelector } from 'react-redux'
import { useGetDocumentsListByProjectQuery } from '../../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { Tooltip } from 'react-tooltip'

const DocumentViewerDocumentSelect: React.FC = () => {
  const navigate = UseUrlNavigate()
  const [searchParams] = useSearchParams()
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )

  const setSelectedDocument = useCallback(
    (projectDocument: ProjectDocumentMetadata) => {
      const page = parseInt(searchParams.get('page') ?? '')
      navigate.navigateDocumentUrl(projectDocument?.uuid, {
        page: page ? page : undefined,
      })
    },
    [navigate, searchParams]
  )

  const isLatestRevision = useMemo(() => {
    if (documents === undefined) {
      return true
    } else {
      return (
        // eslint-disable-next-line eqeqeq
        documents?.filter((d) => d.v1_document === currentDocument?.id)
          .length === 0
      )
    }
  }, [documents, currentDocument])

  const onSwitchToLatestRevision = useCallback(() => {
    const latestRevision = documents?.find(
      // eslint-disable-next-line eqeqeq
      (d) => d.v1_document == currentDocument?.id
    )
    if (latestRevision) {
      setSelectedDocument(latestRevision)
    }
  }, [documents, currentDocument, setSelectedDocument])

  const currentDocumentMetaData = useMemo(() => {
    if (!currentDocument) {
      return null
    }
    return {
      uuid: currentDocument.uuid,
      id: currentDocument.id,
      title: currentDocument.title,
      total_pages: currentDocument.total_pages,
      v1_document: currentDocument.v1_document,
      job_status: currentDocument.job_status,
    }
  }, [currentDocument])

  return (
    <div className="flex items-stretch justify-between gap-2">
      <div className={'flex-none'}>
        <DocumentListbox
          noDefault
          documents={documents ?? []}
          setSelectedDocument={setSelectedDocument}
          selectedDocument={currentDocumentMetaData ?? null}
        />
      </div>
      {!isLatestRevision && (
        <div
          className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800"
          data-tooltip-id="latest-revision-id"
          data-tooltip-content="This document has a newer revision available. Click here to switch to the latest revision."
        >
          <button
            className="text-ellipsis whitespace-nowrap"
            onClick={onSwitchToLatestRevision}
          >
            Not the latest revision
          </button>
          <Tooltip id={'latest-revision-id'} style={{ zIndex: 100 }} />
        </div>
      )}
    </div>
  )
}
export default DocumentViewerDocumentSelect
