import React, { useCallback } from 'react'
import { Workflow } from './workflow-popover'
import { useNavigateWorkflow } from '../../../hooks/use-navigate-workflow'

interface WorkflowButtonProps {
  workflow: Workflow
  setSelectedWorkflow: React.Dispatch<React.SetStateAction<Workflow | null>>
  referenceElement: HTMLElement | null
  index: number
}

const WorkflowButton: React.FC<WorkflowButtonProps> = ({
  workflow,
  setSelectedWorkflow,
  referenceElement,
  index,
}) => {
  const { navigateWorkflow } = useNavigateWorkflow()
  const onClickWorkflowButton = useCallback(() => {
    navigateWorkflow(workflow)
    referenceElement?.click()
    setSelectedWorkflow(workflow)
  }, [workflow, referenceElement, setSelectedWorkflow, navigateWorkflow])

  return (
    <button
      key={workflow.title}
      className="flex cursor-pointer items-center space-x-2 px-2 py-3 hover:bg-gray-100"
      onClick={onClickWorkflowButton}
    >
      <div className="flex h-5 w-5 items-center justify-center rounded border bg-gray-50 text-xs text-gray-700">
        <div>{index + 1}</div>
      </div>
      <workflow.Icon className="h-5 w-5 text-gray-400" />
      <div className={'text-gray-900'}>{workflow.title}</div>
    </button>
  )
}

export default WorkflowButton
