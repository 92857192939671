import { useEffect } from 'react'
import { unstable_usePrompt } from 'react-router-dom'

export const usePrompt = ({ when }) => {
  useEffect(() => {
    window.onbeforeunload = when ? () => true : null
    return () => {
      window.onbeforeunload = null
    }
  }, [when])

  unstable_usePrompt({
    message: 'Some files are still uploading. Are you sure you want to leave?',
    when,
  })
}
