import React, { useContext, useEffect, useState, createRef } from 'react'
import {
  selectCurrentPage,
  selectTotalPages,
  setTotalPages,
} from '../redux/viewer-slice'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { useParams } from 'react-router-dom'
import { selectCurrentDocument } from '../redux/application-slice'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'

interface PageCountProps {
  document?: ProjectDocumentMetadata
}

const PageCount: React.FC<PageCountProps> = ({ document }) => {
  const totalPages = useSelector(selectTotalPages)
  const documentViewerContext = useContext(DocumentViewerContext)
  const currentDocument = useSelector(selectCurrentDocument)
  const { documentViewer } = documentViewerContext
  const currentPage = useSelector(selectCurrentPage)
  const { documentId } = useParams<{ documentId: string }>()
  const dispatch = useDispatch()

  const [page, setPage] = useState(currentPage)
  const ref = createRef<HTMLInputElement>()

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    dispatch(setTotalPages(1))
  }, [currentDocument, dispatch])

  const onPageChange = (event) => {
    setPage(event.target.value)
  }

  const onPressEnter = (event) => {
    if (event.key === 'Enter') {
      documentViewer?.setCurrentPage(parseInt(page), false)
      ref?.current?.blur()
    }
  }

  return (
    <div
      className={
        'relative flex items-center gap-2 rounded bg-gray-100 px-2 py-1 shadow-sm'
      }
    >
      <div>
        <input
          disabled={document ? !document?.id : !documentId}
          ref={ref}
          max={totalPages}
          min={1}
          onChange={onPageChange}
          onKeyUp={onPressEnter}
          className={
            'w-10 rounded border-0 bg-gray-200 p-0 text-center opacity-75 focus:bg-white'
          }
          type="text"
          value={page}
        />
      </div>
      <div className="whitespace-nowrap">/ {totalPages}</div>
    </div>
  )
}

export default PageCount
