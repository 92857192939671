import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { SelectedSource, setSelectedSources } from '../../../redux/viewer-slice'
import { v4 as uuidv4 } from 'uuid'
import { ChatSessionMessageSource } from '../../../shared/interfaces/chat/chat-history.interface'

interface AIChatGroupedSourceProps {
  source: ChatSessionMessageSource
  index: number
  groupedSource: ChatSessionMessageSource[]
}

const AIChatGroupedSource: React.FC<AIChatGroupedSourceProps> = ({
  source,
  index,
  groupedSource,
}) => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const onClickGroupedSource = useCallback(() => {
    const page = source.source.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)
    const sources: SelectedSource[] = []
    const distinctSources = groupedSource.reduce<ChatSessionMessageSource[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment
        if (!acc.find((c) => c.document_segment === document_segment_id)) {
          acc.push(citation)
        }
        return acc
      },
      []
    )
    for (const sourceFromGroup of distinctSources) {
      const highlightID = uuidv4()
      sources.push({
        id: highlightID,
        page: parseInt(sourceFromGroup.source.toString()),
        quads: sourceFromGroup.source_quads,
        isPrimary: sourceFromGroup.id === source.id,
      })
    }
    dispatch(setSelectedSources(sources))
  }, [dispatch, groupedSource, searchParams, setSearchParams, source])

  return (
    <button key={`page_source_${source}`} onClick={onClickGroupedSource}>
      <span className="cursor-pointer hover:underline">
        {source.source}
        {index < groupedSource.length - 1 ? ',' : ''}
      </span>
    </button>
  )
}

export default AIChatGroupedSource
