import React, { useCallback, useMemo } from 'react'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SelectedSource, setSelectedSources } from '../../../redux/viewer-slice'
import { v4 as uuidv4 } from 'uuid'
import { selectCurrentProject } from '../../../redux/application-slice'
import { InTextCitation } from '../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'

interface RiskReviewSourceProps {
  citation: InTextCitation
  documents: ProjectDocumentMetadata[]
  sourceIndex: number
  isExpanded: boolean
  citations: InTextCitation[]
}

const RiskReviewSource: React.FC<RiskReviewSourceProps> = ({
  citation,
  documents,
  sourceIndex,
  isExpanded = false,
  citations,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const navigate = useNavigate()
  const sourceDocument = useMemo(() => {
    return documents?.find((d) => d.uuid === citation.document_segment.document)
  }, [citation, documents])

  const onSelectCitation = useCallback(() => {
    navigate(`/${currentProject?.uuid}/riskreview/${sourceDocument?.uuid}`)
    const page = citation.document_segment.page.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)
    const selectedSources: SelectedSource[] = []
    const distinctCitations = citations.reduce<InTextCitation[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment.id
        if (!acc.find((c) => c.document_segment.id === document_segment_id)) {
          acc.push(citation)
        }
        return acc
      },
      []
    )
    for (const citationSource of distinctCitations) {
      const highlightID = uuidv4()
      const quads = citationSource.document_segment.quads ?? []
      selectedSources.push({
        id: highlightID,
        page: citationSource.document_segment.page,
        quads,
        isPrimary: citation.id === citationSource.id,
      })
    }
    dispatch(setSelectedSources(selectedSources))
  }, [
    citation,
    citations,
    currentProject?.uuid,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
    sourceDocument?.uuid,
  ])

  return (
    <button
      onClick={onSelectCitation}
      className={`flex w-full items-center justify-between gap-1 rounded p-1.5 pt-1 text-left text-xs ${
        isExpanded ? 'hover:bg-gray-100' : ''
      }`}
    >
      <div className="relative mr-0.5 h-5 w-5 flex-shrink-0 cursor-pointer rounded-full bg-gray-200 px-1 text-center text-[0.6rem] text-black">
        <div className="relative top-0.5">{sourceIndex + 1}</div>
      </div>
      <div
        className={'flex-grow overflow-hidden text-ellipsis whitespace-nowrap'}
      >
        {citation.document_segment.text}
      </div>
      <div className="flex-shrink-0 text-gray-400">
        {sourceDocument?.title} - pg. {citation.document_segment.page}
      </div>
    </button>
  )
}

export default RiskReviewSource
