import { FilePond } from 'react-filepond'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { FilePondFile } from 'filepond'
import Uploader from './uploader'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import {
  useCreateDocumentMutation,
  useCreateProjectMutation,
} from '../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectV1Document,
  setProjectSidebarOpen,
  setV1Document,
} from '../../redux/application-slice'
import axios from 'axios'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import LoadingCircle from '../loading/loading-circle'
import { useNavigate } from 'react-router-dom'
import { Project } from '../../shared/interfaces/project/project.interface'
import FileUploadPreview from './file-upload-preview'
import { auth0TokenHelper } from '../../utils/auth0-token-helper'

interface DocumentViewDragAndDropProps {
  className: string
  projectName?: string
  onUploadComplete?: () => void
  onClose: () => void
}

export interface FileProgress {
  [fileID: string]: {
    lengthComputable: boolean
    loaded: number
    total: number
  }
}

const DocumentViewDragAndDrop: React.FC<DocumentViewDragAndDropProps> = ({
  onUploadComplete,
  className,
  projectName,
  onClose,
}) => {
  const [files, setFiles] = useState<FilePondFile[]>([])
  const dragUploaderRef = useRef<FilePond | null>(null)
  const [createProject] = useCreateProjectMutation()
  const dispatch = useDispatch()
  const v1Document = useSelector(selectV1Document)
  const posthog = usePostHog()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [createDocument] = useCreateDocumentMutation()
  const [fileProgressRef, setFileProgressRef] = useState<FileProgress>({})
  const [fileLoadCounter, setFileLoadCounter] = useState<number>(0)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesToUpload = acceptedFiles.filter(
      (f) =>
        f.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        f.type === 'application/pdf'
    )
    if (!dragUploaderRef) {
      return
    }
    dragUploaderRef?.current?.addFiles(filesToUpload)
  }, [])

  const processFiles = useCallback(
    async (file: FilePondFile, project: Project) => {
      const formData = new FormData()
      const request = new XMLHttpRequest()
      const v1DocumentID = v1Document
      dispatch(setV1Document(null))
      const response = await createDocument({
        project: project.id,
        title: file?.file?.name,
        v1_document: v1DocumentID,
      }).unwrap()
      const uploadUrl = response.upload_url
      request.open('POST', response.upload_url.url)
      for (const key in uploadUrl.fields) {
        if (uploadUrl.fields[key]) {
          formData.append(key, uploadUrl.fields[key])
        }
      }
      formData.append('file', file?.file, file?.file?.name)

      // Should call the load method when done and pass the returned server file id
      // this server file id is then used later on when reverting or restoring a file
      // so your server knows which file to return without exposing that info to the client
      request.onload = async function () {
        if (request.status >= 200 && request.status < 300) {
          // the load method accepts either a string (id) or an object
          posthog?.capture(POSTHOG.document_uploaded, {
            document_uuid: response.uuid,
          })
          await axios.post(
            `${process.env.REACT_APP_PROVISION_API_BASE_URL}documents/${response.uuid}/complete/`,
            {
              project: project.id,
              title: file.file.name,
              v1_document: v1DocumentID,
            },
            {
              headers: {
                Authorization: `Bearer ${await auth0TokenHelper.getAccessTokenSilently()()}`,
              },
            }
          )
          setFileLoadCounter((prev) => prev + 1)
        } else {
          // Can call the error method if something is wrong, should exit after
        }
      }
      request.upload.onprogress = (e) => {
        setFileProgressRef((prev) => ({
          ...prev,
          [file.id]: {
            lengthComputable: e.lengthComputable,
            loaded: e.loaded,
            total: e.total,
          },
        }))
      }

      request.send(formData)
    },
    [createDocument, dispatch, posthog, v1Document]
  )

  const filesToDisplay = useMemo(() => {
    if (!files) {
      return []
    }
    const uniqueFiles = files.reduce<FilePondFile[]>((acc, file) => {
      if (acc.find((f) => f.filename === file.filename)) {
        return acc
      }
      return [...acc, file]
    }, [])
    return uniqueFiles
  }, [files])

  const onFinish = useCallback(async () => {
    const project = await createProject({ title: projectName }).unwrap()
    navigate(`/${project.uuid}/documents`)
    dispatch(setProjectSidebarOpen(false))
    setIsLoading(true)
    for (const file of filesToDisplay) {
      if (fileProgressRef[file.id]) {
        continue
      }
      await processFiles(file, project)
    }
  }, [
    createProject,
    projectName,
    navigate,
    dispatch,
    filesToDisplay,
    fileProgressRef,
    processFiles,
  ])

  useEffect(() => {
    if (fileLoadCounter === 0 || files.length === 0) {
      return
    }
    if (fileLoadCounter === files.length && onUploadComplete) {
      setIsLoading(false)
      setFiles([])
      onUploadComplete()
    }
  }, [fileLoadCounter, files.length, onUploadComplete])

  const onClickUpload = useCallback(() => {
    dragUploaderRef?.current?.browse()
  }, [dragUploaderRef])

  const onDocumentUploaded = useCallback(() => {
    //overriding onDocumentUploaded
  }, [])

  const onRemove = useCallback((file: FilePondFile) => {
    setFiles((prev) => prev.filter((f) => f.filename !== file.filename))
  }, [])

  return (
    <div className="flex w-full flex-col">
      <div className="flex-1">
        <Dropzone noClick onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div className={className} {...getRootProps()}>
              <input {...getInputProps()} />
              <button
                onClick={onClickUpload}
                className="flex h-64 w-full cursor-pointer items-center justify-center rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <div>
                  <div className="hidden">
                    <Uploader
                      ref={dragUploaderRef}
                      shouldConfirmUpload
                      files={files}
                      setFiles={setFiles}
                      onDocumentUploaded={onDocumentUploaded}
                    />
                  </div>
                  <div className="flex items-center">
                    <LockClosedIcon className="h-4 w-5" /> Secure Document
                    Upload
                  </div>
                </div>
              </button>
            </div>
          )}
        </Dropzone>
        <div className="h-28 overflow-auto">
          {filesToDisplay.map((f) => (
            <FileUploadPreview
              fileProgressRef={fileProgressRef}
              onRemove={onRemove}
              key={f.id}
              file={f}
            />
          ))}
        </div>
      </div>
      <div className="flex space-x-2 pb-1">
        <button
          disabled={isLoading}
          type="button"
          className="text-gray-00 inline-flex w-full justify-center rounded-md border bg-white px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2"
          onClick={onClose}
        >
          Skip for now
        </button>
        <button
          type="button"
          disabled={!projectName || isLoading}
          className={`inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:col-start-2 ${
            !projectName
              ? 'bg-indigo-300 focus-visible:outline-indigo-300'
              : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
          }}`}
          onClick={onFinish}
        >
          <LoadingCircle
            className="mr-2 mt-0.5 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
            isSpinning={isLoading}
          />
          Upload and Create
        </button>
      </div>
    </div>
  )
}

export default DocumentViewDragAndDrop
