import React, { useCallback } from 'react'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import { useMarkSupplementaryConditionsQACompleteMutation } from '../../redux/api/document-change-template-api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { Tooltip } from 'react-tooltip'

const MarkSCCompleteButton: React.FC = () => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const [markSCQAComplete] = useMarkSupplementaryConditionsQACompleteMutation()

  const onMarkSCComplete = useCallback(
    (e) => {
      if (!currentProject?.id) {
        return
      }
      markSCQAComplete({
        project: currentProject?.id,
        uuid: currentDocument?.uuid,
      })
    },
    [currentDocument?.uuid, currentProject?.id, markSCQAComplete]
  )

  return user?.feature_flags?.editor_mode === true ? (
    <>
      <button
        className={`rounded px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-300  ${currentDocument?.job_status === 'IN_REVIEW' ? 'bg-white text-gray-900 hover:bg-gray-50' : 'bg-gray-100 text-gray-400'}`}
        onClick={onMarkSCComplete}
        disabled={currentDocument?.job_status !== 'IN_REVIEW'}
        data-tooltip-id={
          currentDocument?.job_status === 'IN_REVIEW'
            ? 'mark-sc-complete-button'
            : ''
        }
        data-tooltip-content={`A notification will be sent to the document's uploader`}
      >
        Mark as complete
      </button>
      <Tooltip id={'mark-sc-complete-button'} />
    </>
  ) : null
}

export default MarkSCCompleteButton
