import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Definition } from '../../shared/interfaces/definition/definition.interface'
import { selectCurrentProject } from '../../redux/application-slice'
import {
  useGetDefinitionsByQueryQuery,
  useGetDocumentsListByProjectQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import SidebarDefinitionButton from './sidebar-definition-button'
import {
  selectDefinitionQuery,
  setDefinitionQuery,
} from '../../redux/search-slice'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'

const SidebarDefinitions = () => {
  const currentProject = useSelector(selectCurrentProject)
  const defQuery = useSelector(selectDefinitionQuery)

  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[] | null
  >(null)
  const { data: definitionsData, isFetching: definitionsFetching } =
    useGetDefinitionsByQueryQuery(
      selectedDocuments
        ? {
            documentIds: selectedDocuments.map((d) => d.uuid),
          }
        : currentProject?.id
          ? { projectId: currentProject?.id }
          : skipToken
    )
  const dispatch = useDispatch()

  const onChangeDefinitionQuery = useCallback(
    (e) => {
      dispatch(setDefinitionQuery(e.target.value))
    },
    [dispatch]
  )

  const setProjectDocumentFilter = useCallback(
    (projectDocuments: ProjectDocumentMetadata[]) => {
      setSelectedDocuments(projectDocuments)
    },
    []
  )

  return (
    <div className="flex h-full min-h-[10rem] flex-col space-y-2 p-3 px-2">
      <div className="relative mb-2 mt-1 flex flex-initial flex-col items-center space-y-2">
        <DocumentListboxMulti
          documents={documents ?? []}
          setSelectedDocuments={setProjectDocumentFilter}
          selectedDocuments={selectedDocuments ?? []}
        />
        <input
          type="text"
          name="search"
          id="search"
          value={defQuery}
          className="block w-full resize-none rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={'Search definitions'}
          onChange={onChangeDefinitionQuery}
        />
      </div>
      <div className={'min-h-0 flex-1 overflow-auto'}>
        <div className={'space-y-2.5 overflow-y-auto'}>
          {definitionsData &&
            Object.values(definitionsData as Definition[]).filter(
              (def: Definition) =>
                def.text.toLowerCase().includes(defQuery.toLowerCase())
            ).length === 0 && (
              <div className={'mt-3 text-center text-xs text-gray-400'}>
                No matching definitions
              </div>
            )}
          {!defQuery && (
            <div className={'mt-9 text-center text-xs text-gray-400'}>
              Enter a definition to search
            </div>
          )}
          {definitionsFetching ? (
            <div className={'mt-5 text-center text-xs text-gray-400'}>
              Loading...
            </div>
          ) : (
            defQuery &&
            definitionsData
              ?.filter((def: Definition) =>
                def.text.toLowerCase().includes(defQuery.toLowerCase())
              )
              .slice(0, 10)
              .map((definition: Definition) => (
                <SidebarDefinitionButton
                  key={`definition_${definition.id}`}
                  definition={definition}
                  documents={documents ?? []}
                />
              ))
          )}
        </div>
      </div>
    </div>
  )
}
export default SidebarDefinitions
