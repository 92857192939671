import React, { useCallback } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  selectCurrentProject,
  selectProjectSidebarOpen,
  setProjectSidebarOpen,
} from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const ProjectSelector: React.FC = () => {
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const currentProject = useSelector(selectCurrentProject)
  const projectSidebarOpen = useSelector(selectProjectSidebarOpen)

  const onProjectSelect = useCallback(() => {
    dispatch(setProjectSidebarOpen(!projectSidebarOpen))
  }, [dispatch, projectSidebarOpen])

  return (
    <div>
      <button
        onClick={onProjectSelect}
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {`${
          projectId && !currentProject
            ? 'Loading...'
            : currentProject
              ? currentProject.title
              : 'Select a Project'
        }`}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </button>
    </div>
  )
}

export default ProjectSelector
