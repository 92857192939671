/* eslint-disabl @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react'
import { ProjectRisk } from '../../../shared/interfaces/project/risk/risk-inteface'
import { useUpdateProjectRiskMutation } from '../../../redux/api/project-risk-api-slice'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useSelector } from 'react-redux'
import { selectRiskWorkflowFilterState } from '../../../redux/workflow-slice'

interface RiskReviewCardRankingSelectProps {
  projectRisk: ProjectRisk
}

const RiskReviewCardRankingSelect: React.FC<
  RiskReviewCardRankingSelectProps
> = ({ projectRisk }) => {
  const [updateProjectRisk] = useUpdateProjectRiskMutation()
  const currentProject = useSelector(selectCurrentProject)
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  const updateRiskRanking = useCallback(
    (value: number) => {
      if (!projectRisk?.id || !currentProject?.uuid) return
      updateProjectRisk({
        id: projectRisk?.id,
        riskRanking: value,
        projectUUID: currentProject?.uuid,
        sort: riskWorkflowFilterState?.sort,
      })
    },
    [
      projectRisk?.id,
      currentProject?.uuid,
      updateProjectRisk,
      riskWorkflowFilterState?.sort,
    ]
  )

  const onSetHighRiskRanking = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updateRiskRanking(3)
    },
    [updateRiskRanking]
  )

  const onSetMediumRiskRanking = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updateRiskRanking(2)
    },
    [updateRiskRanking]
  )

  const onSetLowRiskRanking = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updateRiskRanking(1)
    },
    [updateRiskRanking]
  )

  return (
    <div
      className="border rounded divide-x text-sm overflow-hidden"
      title="Risk ranking"
    >
      <button
        onClick={onSetHighRiskRanking}
        className={`p-1 hover:bg-gray-100 ${projectRisk.risk_ranking === 3 && 'bg-red-300'}`}
      >
        High
      </button>
      <button
        onClick={onSetMediumRiskRanking}
        className={`p-1 hover:bg-gray-100 ${projectRisk.risk_ranking === 2 && 'bg-orange-300'}`}
      >
        Medium
      </button>
      <button
        onClick={onSetLowRiskRanking}
        className={`p-1 hover:bg-gray-100 ${projectRisk.risk_ranking === 1 && 'bg-green-300'}`}
      >
        Low
      </button>
    </div>
  )
}

export default RiskReviewCardRankingSelect
