import React, { useEffect, useMemo } from 'react'
import DocumentSidebar from './document-sidebar'
import WorkflowSidebar from './workflow-sidebar'
import { selectDocumentViewerSidebarOpen } from '../redux/viewer-slice'
import { useSelector, useDispatch } from 'react-redux'
import useWindowDimensions from '../hooks/use-window-dimensions'
import DocumentViewerSidebar from './document-viewer-sidebar'
import {
  selectApplicationSidebarOpen,
  selectCurrentProject,
  setCurrentDocument,
  setProjectSidebarOpen,
} from '../redux/application-slice'
import { useTabWidth } from '../hooks/use-tab-width'
import { useParams, useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetDocumentByUuidQuery,
  useGetDocumentsListByProjectQuery,
} from '../redux/api-slice'
import UseUrlNavigate from '../hooks/use-url-navigate'
import { useOnlineStatus } from '../hooks/use-online-status'
import { toast } from 'react-toastify'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { Tooltip } from 'react-tooltip'

/**
 * Home component
 */
const Home: React.FC = () => {
  const { width } = useWindowDimensions()
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const applicationSidebarOpen = useSelector(selectApplicationSidebarOpen)
  const { tabWidth, handler, setDefaultTabWidth } = useTabWidth()
  const { projectId, documentId } = useParams()
  const currentProject = useSelector(selectCurrentProject)
  const [searchParams] = useSearchParams()
  const searchQuery = useMemo(() => {
    return searchParams.get('search') ?? undefined
  }, [searchParams])
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject?.id
      ? {
          projectId: currentProject?.id,
          search_query: searchQuery,
        }
      : skipToken
  )
  const urlNavigate = UseUrlNavigate()
  const dispatch = useDispatch()
  const online = useOnlineStatus()

  useEffect(() => {
    setDefaultTabWidth()
  }, [setDefaultTabWidth])

  useEffect(() => {
    if (projectId) {
      return
    }
    dispatch(setProjectSidebarOpen(true))
  }, [dispatch, projectId])

  const initialDocument: ProjectDocumentMetadata | null = useMemo(() => {
    if (!documents) {
      return null
    }
    const v1Documents: {
      [id: string]: ProjectDocumentMetadata
    } = {}
    for (const document of documents.filter((d) => !d.folder)) {
      if (document.v1_document) {
        v1Documents[document.v1_document] = document
      }
    }
    return documents.filter(
      (document) => !v1Documents[document?.id?.toString()]
    )?.[0]
  }, [documents])

  const { currentData: firstDocument } = useGetDocumentByUuidQuery(
    initialDocument?.uuid ?? skipToken
  )

  useEffect(() => {
    if (!projectId) {
      return
    }
    if (!documentId) {
      if (!firstDocument?.uuid) {
        return
      }
      if (currentProject?.uuid !== projectId) {
        return
      }
      dispatch(setCurrentDocument(firstDocument))
      urlNavigate.navigateDocumentUrl(firstDocument.uuid, {
        replace: true,
      })
    }
  }, [
    projectId,
    documentId,
    firstDocument,
    currentProject,
    urlNavigate,
    dispatch,
  ])

  useEffect(() => {
    if (!online) {
      toast.error('You are offline. Check your network connection.', {
        toastId: 'offline',
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        pauseOnHover: false,
        position: 'top-right',
        closeButton: false,
        className: 'bg-gray-500 text-gray-100',
      })
    } else {
      toast.dismiss('offline')
    }
  }, [online])

  return (
    <div
      className="flex h-full w-full overflow-hidden"
      style={{
        maxHeight: 'calc(100vh)',
      }}
    >
      {applicationSidebarOpen !== 'right' && (
        <div
          className={`flex h-full flex-1 ${
            applicationSidebarOpen === 'left' ? 'w-full' : ''
          }`}
        >
          <DocumentSidebar
            width={
              applicationSidebarOpen === 'left' ? '100%' : width - tabWidth - 20
            }
          />
        </div>
      )}

      <div className="relative flex">
        {documentViewerSidebarOpen && <DocumentViewerSidebar />}
        {!applicationSidebarOpen && (
          <button
            className={
              'flex cursor-col-resize select-none items-center border border-gray-100 px-0.5 text-xs hover:border-gray-200'
            }
            onMouseDown={handler}
          >
            =
          </button>
        )}
      </div>
      {applicationSidebarOpen !== 'left' && (
        <div
          className={`overflow-hidden  ${
            applicationSidebarOpen === 'right' ? 'w-full' : ''
          }`}
        >
          <WorkflowSidebar
            width={applicationSidebarOpen === 'right' ? '100%' : tabWidth}
          />
        </div>
      )}
      <Tooltip id={'document-title-single-tooltip'} style={{ zIndex: 100 }} />
      <Tooltip id={'document-title-tooltip'} style={{ zIndex: 100 }} />
    </div>
  )
}
export default Home
