import React, { useCallback, useMemo, useState } from 'react'
import {
  ProjectRisk,
  ProjectRiskComment,
} from '../../../shared/interfaces/project/risk/risk-inteface'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import RiskReviewSource from './risk-review-source'
import RiskReviewAnswerTextCitation from './risk-review-answer-text-citation'
import { InTextCitation } from '../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'
import RiskReviewCardComment from './risk-review-card-comment'
import {
  ChatBubbleLeftIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useUpdateProjectRiskMutation } from '../../../redux/api/project-risk-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  setModal,
} from '../../../redux/application-slice'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import RiskReviewCardRankingSelect from './risk-review-card-ranking-select'
import { selectRiskWorkflowFilterState } from '../../../redux/workflow-slice'

interface RiskReviewCardProps {
  projectRisk: ProjectRisk
  documents: ProjectDocumentMetadata[]
}

interface GroupedEndIndex {
  [index: number]: InTextCitation[]
}

const RiskReviewCard: React.FC<RiskReviewCardProps> = ({
  projectRisk,
  documents,
}) => {
  const [commentSelected, setCommentSelected] =
    useState<ProjectRiskComment | null>(null)
  const [sourcesExpanded, setSourcesExpanded] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [updateProjectRisk] = useUpdateProjectRiskMutation()
  const currentProject = useSelector(selectCurrentProject)
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  const renderAnswerText = useMemo(() => {
    if (!projectRisk?.sources || !projectRisk?.answer_text) {
      return null
    }
    const sortedCitations = projectRisk.sources
      .filter(
        (citation) =>
          citation.start_index !== null && citation.end_index !== null
      )
      .sort((a, b) => (a?.start_index ?? 0) - (b?.start_index ?? 0))
    const answerTexts: JSX.Element[] = []
    let lastEndIndex = 0
    const groupedEndIndexes = sortedCitations.reduce<GroupedEndIndex>(
      (acc, citation) => {
        if (acc[citation.end_index ?? 0]) {
          acc[citation.end_index ?? 0].push(citation)
        } else {
          acc[citation.end_index ?? 0] = [citation]
        }
        return acc
      },
      {}
    )
    let total = 0
    for (const groupedIndex of Object.keys(groupedEndIndexes)) {
      const endIndex = parseInt(groupedIndex)
      const citations = groupedEndIndexes[endIndex]
      answerTexts.push(
        <span key={projectRisk?.answer_text?.slice(lastEndIndex, endIndex)}>
          {projectRisk?.answer_text?.slice(lastEndIndex, endIndex)}
        </span>
      )
      for (const citation of citations) {
        answerTexts.push(
          <RiskReviewAnswerTextCitation
            key={`source_${citation.document_segment.quads?.[0].x1}${citation.document_segment.quads?.[0].y1}_${citation.start_index}_${citation.end_index}`}
            index={total}
            citation={citation}
            citations={citations}
          />
        )
        total += 1
      }
      lastEndIndex = endIndex
    }
    answerTexts.push(
      <span key={projectRisk?.answer_text?.slice(lastEndIndex)}>
        {projectRisk?.answer_text?.slice(lastEndIndex)}
      </span>
    )
    return answerTexts
  }, [projectRisk])

  const onClickExpand = useCallback(() => {
    if (projectRisk.processing_status === 0) {
      return
    }
    setIsExpanded((s) => !s)
    posthog?.capture(POSTHOG.risk_review_risk_expanded, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
  }, [projectRisk, currentProject, posthog])

  const onClickApproved = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_status_approved, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 1 ? 0 : 1,
      projectUUID: currentProject?.uuid ?? '',
      sort: riskWorkflowFilterState?.sort,
    })
  }, [
    posthog,
    currentProject?.uuid,
    projectRisk?.risk_name,
    projectRisk?.id,
    projectRisk?.status,
    updateProjectRisk,
    riskWorkflowFilterState?.sort,
  ])

  const onClickNotApproved = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_status_not_approved, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 2 ? 0 : 2,
      projectUUID: currentProject?.uuid,
      riskStatusFilter: riskWorkflowFilterState.status,
      sort: riskWorkflowFilterState?.sort,
    })
  }, [
    posthog,
    currentProject?.uuid,
    projectRisk?.risk_name,
    projectRisk?.id,
    projectRisk?.status,
    updateProjectRisk,
    riskWorkflowFilterState.status,
    riskWorkflowFilterState?.sort,
  ])

  const onClickNeedsReview = useCallback(() => {
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 3 ? 0 : 3,
      projectUUID: currentProject?.uuid,
      riskStatusFilter: riskWorkflowFilterState?.status,
      sort: riskWorkflowFilterState?.sort,
    })
  }, [
    currentProject?.uuid,
    projectRisk?.id,
    projectRisk?.status,
    riskWorkflowFilterState?.sort,
    riskWorkflowFilterState?.status,
    updateProjectRisk,
  ])

  const riskOptions = useMemo(() => {
    return (
      <div className="flex space-x-2">
        {projectRisk?.comments?.length ? (
          <div className="text-md flex items-center space-x-1">
            <div>
              <ChatBubbleLeftIcon className="h-5 w-5" />{' '}
            </div>
            <div>{projectRisk?.comments?.length}</div>
          </div>
        ) : null}
        <RiskReviewCardRankingSelect projectRisk={projectRisk} />

        <div
          className="flex divide-x overflow-hidden rounded border"
          title="Review status"
        >
          <button
            data-playwright={`risk-review-card-approve-${projectRisk?.id}`}
            onClick={onClickApproved}
            data-tooltip-id="risk-info-id"
            data-tooltip-content="Set as approved"
            data-tooltip-delay-show={1500}
            className={`flex w-7 items-center justify-center  ${
              projectRisk?.status === 1 ? 'bg-green-300' : 'hover:bg-green-300'
            }`}
          >
            <CheckIcon className="h-5 w-5" />
          </button>
          <button
            data-playwright={`risk-review-card-not-approve-${projectRisk?.id}`}
            data-tooltip-id="risk-info-id"
            data-tooltip-content="Set as not approved"
            data-tooltip-delay-show={1500}
            onClick={onClickNotApproved}
            className={`flex w-7 items-center justify-center ${
              projectRisk?.status === 2 ? 'bg-red-300' : 'hover:bg-red-300'
            }`}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
          <button
            data-playwright={`risk-review-card-needs-review-${projectRisk?.id}`}
            data-tooltip-id="risk-info-id"
            data-tooltip-content="Set as needs review"
            data-tooltip-delay-show={1500}
            onClick={onClickNeedsReview}
            className={`flex w-7 items-center justify-center ${
              projectRisk?.status === 3 ? 'bg-gray-200' : 'hover:bg-gray-200'
            }`}
          >
            <QuestionMarkCircleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    )
  }, [onClickApproved, onClickNeedsReview, onClickNotApproved, projectRisk])

  const onClickSources = useCallback(() => {
    setSourcesExpanded((s) => !s)
  }, [])

  const onPositiveFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: true, project_risk: projectRisk.id },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onNegativeFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: false, project_risk: projectRisk.id },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  return (
    <div className="flex w-full flex-grow flex-col space-y-2 rounded border hover:shadow-sm">
      <div
        className={`pulse flex w-full items-start justify-between px-3 py-2 ${
          projectRisk.processing_status === 0 ? 'bg-gray-100' : ''
        }`}
      >
        <button
          className="flex grow justify-between space-y-1 text-left"
          onClick={onClickExpand}
          disabled={projectRisk.processing_status === 0}
        >
          <div>
            <h2 className={'font-semibold'}>{projectRisk?.risk_name}</h2>
          </div>
        </button>
        {projectRisk.processing_status === 0 ? (
          <div className="text-xs">Risk Analysing...</div>
        ) : null}
        {projectRisk.processing_status === 1 ? (
          <div className="flex shrink-0 gap-1">{riskOptions}</div>
        ) : null}
      </div>
      {isExpanded && (
        <>
          <div className="space-y-2 px-3 text-sm">
            <div>{projectRisk?.risk_description}</div>
            {projectRisk?.answer_text && (
              <>
                <div>
                  <div className="text-md mb-1 font-semibold">Analysis</div>
                  <div className="whitespace-pre-wrap">{renderAnswerText}</div>
                </div>
              </>
            )}
            <div className="mb-2">
              <button
                onClick={onClickSources}
                className="text-md mb-1 flex w-full items-center justify-start rounded py-1 font-semibold"
              >
                Sources
                {sourcesExpanded ? (
                  <ChevronUpIcon className="ml-1 h-3 w-3" />
                ) : (
                  <ChevronDownIcon className="ml-1 h-3 w-3" />
                )}
              </button>
              {sourcesExpanded && (
                <div className="space-y-1">
                  {projectRisk?.sources && projectRisk?.sources.length > 0 ? (
                    projectRisk?.sources?.map((citation, index) => {
                      return (
                        <RiskReviewSource
                          key={`risk_review_source_${projectRisk.id}${citation.document_segment.quads?.[0].x1}${citation.document_segment.quads?.[0].y1}`}
                          isExpanded={isExpanded}
                          sourceIndex={index}
                          documents={documents}
                          citation={citation}
                          citations={projectRisk?.sources ?? []}
                        />
                      )
                    })
                  ) : (
                    <div>There are no sources</div>
                  )}
                </div>
              )}
            </div>
            <div
              className={
                'flex items-center justify-between text-xs text-gray-500'
              }
            >
              <div>
                Provision can make mistakes. Consider checking important
                information.
              </div>
              <div className="flex items-center justify-end gap-3">
                <div>Give us feedback on this analysis</div>
                <div className="flex flex-wrap gap-2">
                  {/* eslint-disable-next-line react/jsx-max-depth */}
                  <button
                    onClick={onPositiveFeedback}
                    className={`w-16 flex-1 rounded bg-gray-100 px-3 py-0.5 hover:bg-green-200`}
                  >
                    GOOD
                  </button>
                  {/* eslint-disable-next-line react/jsx-max-depth */}
                  <button
                    onClick={onNegativeFeedback}
                    className={`w-16 flex-1 rounded bg-gray-100 px-3 py-0.5 hover:bg-red-200`}
                  >
                    BAD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="px-3 pb-3 pt-1">
            <RiskReviewCardComment
              projectRisk={projectRisk}
              commentSelected={commentSelected}
              setCommentSelected={setCommentSelected}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default RiskReviewCard
