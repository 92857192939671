import React from 'react'
import NewProjectModal from './new-project-modal'
import { selectModal } from '../../redux/application-slice'
import { useSelector } from 'react-redux'
import DeleteProjectModal from './delete-project-modal'
import SharingModal from './sharing/sharing-modal'
import RenameDocumentModal from './rename-document-modal'
import AIChatFeedbackModal from './ai-chat-feedback-modal'
import RenameChatModal from './rename-chat-modal'
import AccountLocked from './account-locked-modal'
import RenameProjectModal from './rename-project-modal'
import CommandBarPopup from '../commandbar/command-bar-popup'
import UserFirstNameModal from './user-first-name-modal'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import ExportOptionsModal from './export-options-modal'
import CreateFolderModal from './create-folder-modal'
import RenameFolderModal from './rename-folder-modal'

export const MODAL_TYPES = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  COMMAND_BAR: 'COMMAND_BAR',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  RENAME_DOCUMENT: 'RENAME_DOCUMENT',
  DELETE_CHECKLIST_QUESTION: 'DELETE_CHECKLIST_QUESTION',
  DELETE_CHECKLIST_VIEW: 'DELETE_CHECKLIST_VIEW',
  AI_CHAT_FEEDBACK: 'AI_CHAT_FEEDBACK',
  RENAME_CHAT: 'RENAME_CHAT',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  RENAME_PROJECT: 'RENAME_PROJECT',
  USER_SETTINGS_OPEN: 'USER_SETTINGS_OPEN',
  EXPORT_OPTIONS: 'EXPORT_OPTIONS',
  CREATE_FOLDER: 'CREATE_FOLDER',
  RENAME_FOLDER: 'RENAME_FOLDER',
}

function ModalController() {
  const modalOpen = useSelector(selectModal)
  const { data: profile } = useGetUserProfileQuery(undefined)

  return (
    <>
      <NewProjectModal open={modalOpen?.modal === MODAL_TYPES.CREATE_PROJECT} />
      <DeleteProjectModal
        open={modalOpen?.modal === MODAL_TYPES.DELETE_PROJECT}
      />
      <CommandBarPopup open={modalOpen?.modal === MODAL_TYPES.COMMAND_BAR} />
      <SharingModal open={modalOpen?.modal === MODAL_TYPES.SHARE_PROJECT} />
      <RenameDocumentModal
        open={modalOpen?.modal === MODAL_TYPES.RENAME_DOCUMENT}
      />
      <RenameChatModal open={modalOpen?.modal === MODAL_TYPES.RENAME_CHAT} />
      <AIChatFeedbackModal
        open={modalOpen?.modal === MODAL_TYPES.AI_CHAT_FEEDBACK}
      />
      {modalOpen?.modal === MODAL_TYPES.ACCOUNT_LOCKED ? (
        <AccountLocked open={modalOpen?.modal === MODAL_TYPES.ACCOUNT_LOCKED} />
      ) : null}
      <RenameProjectModal
        open={modalOpen?.modal === MODAL_TYPES.RENAME_PROJECT}
      />
      <UserFirstNameModal
        open={Boolean(
          (!profile?.first_name || !profile?.last_name) &&
            profile?.agreed_tandcs
        )}
      />
      <ExportOptionsModal
        open={modalOpen?.modal === MODAL_TYPES.EXPORT_OPTIONS}
      />
      {modalOpen?.modal === MODAL_TYPES.CREATE_FOLDER ? (
        <CreateFolderModal
          open={modalOpen?.modal === MODAL_TYPES.CREATE_FOLDER}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.RENAME_FOLDER ? (
        <RenameFolderModal
          open={modalOpen?.modal === MODAL_TYPES.RENAME_FOLDER}
        />
      ) : null}
    </>
  )
}

export default ModalController
