import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectDocumentSegmentsParams,
  setDocumentSegmentsParams,
} from '../../../redux/application-slice'
import {
  useCreateTypesenseExportMutation,
  useGetDocumentsListByProjectQuery,
  useGetDocumentSegmentsQuery,
} from '../../../redux/api-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  selectAssignedUsers,
  selectFilterDocumentUuids,
  selectFilterLabelNames,
  selectSearchQuery,
  setAssignedUsers,
  setFilterDocumentUuids,
  setFilterLabelNames,
  setSearchQuery,
} from '../../../redux/search-slice'
import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import { v4 as uuidv4 } from 'uuid'
import CommentLoadingCard from '../comment-table/comment-loading-card'
import ExportPopover from '../workflow-components/export-popover'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import {
  DocumentSegment,
  DocumentSegmentSearchParams,
} from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { useGetCustomLabelsQuery } from '../../../redux/api/custom-label-api-slice'
import { CustomLabel } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'
import debounce from 'lodash/debounce'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { VariableSizeList } from 'react-window'
import FilteringScrollerRow from './filtering-scroller-row'
import useWindowDimensions from '../../../hooks/use-window-dimensions'
import {
  selectClauseSelectedDocuments,
  setClauseSelectedDocuments,
  setTaskOpen,
} from '../../../redux/viewer-slice'
import FilteringPageTaskFilter from './filtering-page-task-filter'
import { ProjectPermissionUser } from '../../../shared/interfaces/project/permissions/project-permissions.interface'
import FilterDisplay from '../workflow-components/filter-display'
import DocumentListboxMulti from '../../document-listbox/document-listbox-multi'

interface SegmentContextProps {
  setSize?: (index: number, size: number) => void
  windowDimensions?: { width: number; height: number }
  query?: string
  labelFilters?: number[]
  selectedDocuments?: ProjectDocumentMetadata[] | null
}

export const SegmentContext = createContext<SegmentContextProps>({})

// const PAGE_SIZE = 100;

function Filtering() {
  const { projectId } = useParams()
  const windowDimensions = useWindowDimensions()
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const { currentData: documents, isLoading: isDocumentsLoading } =
    useGetDocumentsListByProjectQuery(
      currentProject ? { projectId: currentProject?.id } : skipToken
    )
  const posthog = usePostHog()
  const [selectedDocumentSegment, setSelectedDocumentSegment] =
    useState<DocumentSegment | null>(null)
  const searchQuery = useSelector(selectSearchQuery)
  const [currentSearch, setCurrentSearch] = useState<string>(searchQuery)
  const filterLabelNames = useSelector(selectFilterLabelNames) as CustomLabel[]
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const filteredUsers = useSelector(selectAssignedUsers)
  const selectedDocuments = useSelector(selectClauseSelectedDocuments)
  const documentSegmentsParams = useSelector(selectDocumentSegmentsParams)
  useEffect(() => {
    const params: DocumentSegmentSearchParams | null = currentProject
      ? {
          projectId: selectedDocuments?.length
            ? undefined
            : currentProject?.uuid,
          documentIds: selectedDocuments?.length
            ? selectedDocuments?.map((d) => d.uuid ?? '')
            : undefined,
          query: searchQuery,
          labels: filterLabelNames?.map((f) => f.id),
          page:
            (filteredUsers?.length ?? 0) > 0 || filterLabelNames?.length > 0
              ? 1
              : page,
          assigned_user:
            (filteredUsers?.length ?? 0) > 0
              ? filteredUsers?.[0].id
              : undefined,
        }
      : null
    dispatch(setDocumentSegmentsParams(params))
  }, [
    currentProject,
    dispatch,
    filterLabelNames,
    filteredUsers,
    page,
    searchQuery,
    selectedDocuments,
  ])
  const { currentData: documentSegments, isFetching: segmentsFetching } =
    useGetDocumentSegmentsQuery(
      documentSegmentsParams ? documentSegmentsParams : skipToken
    )

  const [searchParams, setSearchParams] = useSearchParams()

  const currentFilterNames = searchParams.getAll('label')

  const infiniteLoaderRef = useRef<InfiniteLoader | null>(null)
  const variableSizeRef = useRef<VariableSizeList | null>(null)

  const { currentData: customLabels } = useGetCustomLabelsQuery(
    currentProject?.uuid
      ? { projectUUID: currentProject?.uuid, segmentsIncluded: false }
      : skipToken
  )

  const sizeMap = useRef({})
  const setSize = useCallback((index, size) => {
    variableSizeRef.current?.resetAfterIndex(index - 1, true)
    sizeMap.current = { ...sizeMap.current, [index]: size }
  }, [])

  const setSelectedDocuments = useCallback(
    (projectDocuments: ProjectDocumentMetadata[] | null) => {
      setPage(1)
      dispatch(setClauseSelectedDocuments(projectDocuments))
    },
    [dispatch]
  )

  const [createTypesenseExport] = useCreateTypesenseExportMutation()
  const filterDocumentUuids = useSelector(selectFilterDocumentUuids)

  const uniqueDocumentSegments = useMemo(() => {
    return (
      documentSegments?.results.filter(
        (segment, index, self) =>
          index ===
          self.findIndex((t) => t.id === segment.id && t.id === segment.id)
      ) ?? []
    )
  }, [documentSegments])

  const onClear = useCallback(() => {
    dispatch(setFilterLabelNames([]))
    setCurrentSearch('')
    setSelectedDocuments(null)
    dispatch(setFilterDocumentUuids([]))
    dispatch(setSearchQuery(''))
  }, [dispatch, setSelectedDocuments])

  const onClickClear = useCallback(() => {
    searchParams.delete('label')
    setSearchParams(searchParams)
    onClear()
  }, [searchParams, setSearchParams, onClear])

  useEffect(() => {
    posthog?.capture(POSTHOG.clause_filtering_opened, {
      project_uuid: currentProject?.uuid,
    })
    return () => {
      onClear()
    }
  }, [posthog, currentProject, onClear])

  useEffect(() => {
    const labels = searchParams.getAll('label')
    if (!labels || labels.length === 0) {
      setIsSearching(false)
      dispatch(setFilterLabelNames([]))
      setPage(1)
      return
    }
    const customLabel = customLabels?.filter((label) =>
      labels.includes(label.name)
    )
    setIsSearching(false)
    dispatch(setFilterLabelNames(customLabel))
  }, [customLabels, dispatch, searchParams])

  const onSelected = useCallback(
    (data: string[]) => {
      setIsSearching(true)
      searchParams.delete('label')
      data.forEach((d) => {
        searchParams.append('label', d)
      })
      setSearchParams(searchParams)
      sizeMap.current = {}
      setPage(1)
    },
    [searchParams, setSearchParams]
  )

  const onExport = useCallback(() => {
    createTypesenseExport({
      projectUuid: currentProject?.uuid,
      query: searchQuery,
      documentUuids: filterDocumentUuids,
      labels: filterLabelNames,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
  }, [
    createTypesenseExport,
    currentProject,
    searchQuery,
    filterDocumentUuids,
    filterLabelNames,
  ])

  const onSetSearchQuery = useCallback(
    (e) => {
      setCurrentSearch(e.target.value)
      setIsSearching(true)
      sizeMap.current = {}
      setPage(1)
      debounce(() => {
        setIsSearching(false)
        dispatch(setSearchQuery(e.target.value))
      }, 1000)()
    },
    [dispatch]
  )

  const filteringListbox = useMemo(() => {
    return (
      <Listbox value={currentFilterNames} onChange={onSelected} multiple>
        <div className="relative flex h-7 w-full min-w-32 self-stretch">
          <Listbox.Button className="relative w-full overflow-hidden rounded bg-white pl-3 pr-10 text-left text-gray-900 shadow hover:bg-gray-100 sm:text-sm sm:leading-6">
            <span className="block space-x-2">
              {currentFilterNames && currentFilterNames.length > 0 ? (
                currentFilterNames.map((filterValue) => (
                  <span
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-0.5 text-xs font-medium text-gray-600"
                    key={uuidv4()}
                  >
                    {
                      customLabels?.find(
                        (filter) => filter.name === filterValue
                      )?.name
                    }
                  </span>
                ))
              ) : (
                <div className="truncate text-sm text-gray-500">
                  Select a Label
                </div>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options className="absolute top-full z-10 mt-1 max-h-60 w-52 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
            {customLabels?.map((customLabel) => (
              <Listbox.Option
                key={`search_result_filter_${customLabel.id}`}
                value={customLabel.name}
                className={
                  'flex cursor-pointer justify-between px-2 py-2 hover:bg-gray-100'
                }
              >
                {customLabel.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    )
  }, [currentFilterNames, onSelected, customLabels])

  useEffect(() => {
    dispatch(setTaskOpen(null))
  }, [dispatch, segmentsFetching])

  const isItemLoaded = useCallback(
    (index: number) => {
      if (segmentsFetching) {
        return true
      }
      return !documentSegments?.next || index < uniqueDocumentSegments.length
    },
    [documentSegments?.next, uniqueDocumentSegments.length, segmentsFetching]
  )

  const loadMoreItems = useCallback(() => {
    setPage((prevPage) => {
      return prevPage + 1
    })
  }, [])

  const itemSize = useCallback((index) => {
    const currentSize = sizeMap.current[index]
    if (!currentSize) {
      return 0
    }
    return currentSize + 10
  }, [])

  const selectUserFilter = useCallback(
    (user: ProjectPermissionUser) => {
      dispatch(setAssignedUsers([user]))
      setPage(1)
    },
    [dispatch]
  )

  const resetFilter = useCallback(() => {
    dispatch(setAssignedUsers(null))
    setPage(1)
  }, [dispatch])

  return (
    <div className="flex h-full flex-col p-3">
      <div className="flex w-full justify-between">
        <div className="flex w-1/2 space-x-1">
          {documents && (
            <div>
              <DocumentListboxMulti
                documents={documents}
                selectedDocuments={selectedDocuments ?? []}
                setSelectedDocuments={setSelectedDocuments}
              />
            </div>
          )}
          <div className="text-xs">
            <FilteringPageTaskFilter selectUserFilter={selectUserFilter} />
          </div>
        </div>
        <div className={'flex space-x-2'}>
          <ExportPopover
            exportTypes={['excel']}
            onClickExportToExcel={onExport}
          />
        </div>
      </div>
      <div
        className={
          'my-2 flex flex-initial flex-wrap items-center justify-between self-stretch bg-white'
        }
      >
        <div className="flex w-full flex-wrap items-center justify-between gap-2 rounded-sm border border-gray-100 p-2">
          <div className="flex w-1/2 items-center">
            <TextareaAutosize
              className="h-7 w-full resize-none rounded-sm border-0 border-white bg-white p-1 px-3 text-sm text-gray-800 placeholder-gray-400 shadow focus:border focus:border-none focus:border-gray-300 focus:text-black focus:outline-none focus:ring-0"
              placeholder="Search"
              value={currentSearch}
              onChange={onSetSearchQuery}
            />
          </div>
          <div className="flex w-5/12 gap-2">
            {filteringListbox}
            <button
              className="text-md h-7 items-center justify-center rounded-md border bg-white px-4 font-medium text-gray-900 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-xs"
              onClick={onClickClear}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      {filteredUsers?.[0] && (
        <div className="m-1 flex w-fit rounded bg-gray-100 p-1 text-xs">
          <span className="pr-1">Filtering by</span>{' '}
          {
            <FilterDisplay
              filter={{
                comment_author: {
                  ...filteredUsers?.[0],
                  agreed_tandcs: true,
                },
              }}
              setFilter={resetFilter}
            />
          }
        </div>
      )}
      {isSearching || segmentsFetching ? (
        <div className="px-3 text-sm italic text-gray-500">Searching...</div>
      ) : (
        <div className="px-3 text-sm italic text-gray-500">
          {documentSegments?.count ?? 0} results
        </div>
      )}
      {isSearching ? null : (
        <div className="flex-auto overflow-auto px-2">
          {!isDocumentsLoading && currentProject?.uuid === projectId ? (
            <SegmentContext.Provider
              value={{
                setSize,
                windowDimensions,
                query: searchQuery,
                labelFilters: filterLabelNames?.map((f) => f.id),
                selectedDocuments,
              }}
            >
              <AutoSizer>
                {({ height, width }) => (
                  <InfiniteLoader
                    ref={infiniteLoaderRef}
                    isItemLoaded={isItemLoaded}
                    itemCount={documentSegments?.count ?? 0}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (
                      <VariableSizeList
                        itemSize={itemSize}
                        width={width ?? '100%'}
                        height={height ?? 500}
                        overscanCount={4}
                        itemCount={uniqueDocumentSegments.length ?? 0}
                        onItemsRendered={onItemsRendered}
                        ref={(list) => {
                          // Pass List ref through to InfiniteLoader
                          ref(list)

                          // And store a copy for yourself.
                          variableSizeRef.current = list
                        }}
                        itemData={uniqueDocumentSegments}
                      >
                        {(props) => {
                          // eslint-disable-next-line react/prop-types
                          const { data, index, style } = props
                          return (
                            <FilteringScrollerRow
                              page={page}
                              documentSegments={data}
                              index={index}
                              style={style}
                              setSelectedDocumentSegment={
                                setSelectedDocumentSegment
                              }
                              selectedDocumentSegment={selectedDocumentSegment}
                            />
                          )
                        }}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </SegmentContext.Provider>
          ) : (
            <div>
              {Array(6)
                .fill(0)
                .map((_, index) => index)
                .map((loadingCard) => (
                  <CommentLoadingCard
                    key={`loading_commment_card_${loadingCard}`}
                  />
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Filtering
