import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  DocumentIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline'
import { Workflow } from './workflow-popover'

export const workflows: Workflow[] = [
  {
    title: 'Documents',
    Icon: DocumentIcon,
    route: 'documents',
  },
  {
    title: 'Risk Review',
    Icon: ClipboardDocumentCheckIcon,
    route: 'riskreview',
  },
  {
    title: 'Chat',
    Icon: ChatBubbleBottomCenterIcon,
    route: 'chat',
  },
  {
    title: 'Comments',
    Icon: ChatBubbleLeftRightIcon,
    route: 'comments',
  },
  {
    title: 'Clause Filtering',
    Icon: FunnelIcon,
    route: 'clause-filters',
  },

  {
    title: 'Supplementary Conditions',
    Icon: ClipboardDocumentCheckIcon,
    route: 'supplementary-conditions',
  },
  {
    title: 'Quality Assurance',
    Icon: ClipboardDocumentCheckIcon,
    route: 'quality-assurance',
    featureFlag: true,
  },
]
