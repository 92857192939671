import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../redux/application-slice'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import {
  useGetDocumentStatusByProjectQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import NotificationPopover from './notification/notification-popover'
import ProjectSelector from '../nav/project-selector'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HelpPopover from './help-popover'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function NavHeader() {
  const { user, logout } = useAuth0()
  const { data: userProfile } = useGetUserProfileQuery(undefined)
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const [pollingInterval, setPollingInterval] = React.useState(5000)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { currentData: documents } = useGetDocumentStatusByProjectQuery(
    currentProject && currentProject?.id ? currentProject?.id : skipToken,
    {
      pollingInterval,
    }
  )

  useEffect(() => {
    if (!documents) {
      return
    }
    if (
      documents?.every(
        (document) =>
          document.job_status !== 'PENDING' &&
          document.job_status !== 'PROCESSING' &&
          document.job_status !== 'ARCHIVED' &&
          document.job_status !== 'UNARCHIVING'
      )
    ) {
      setPollingInterval(0)
    } else {
      setPollingInterval(5000)
    }
  }, [documents])

  const handleLogout = useCallback(() => {
    posthog?.capture(POSTHOG.user_logged_out)
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [logout, posthog])

  const onBackButton = () => {
    if (currentProject?.uuid) {
      navigate(`/${currentProject?.uuid}/comments`)
    }
  }

  const showCommentViewerBackButtom = useMemo(
    () => pathname.endsWith('commentviewer'),
    [pathname]
  )

  return (
    <div className="relative z-50 flex h-10 items-center justify-between border-b border-gray-200 bg-white px-2">
      <div className={'flex gap-2'}>
        <ProjectSelector />
        {showCommentViewerBackButtom && (
          <button
            className={'flex items-center rounded border px-2 py-0.5 text-sm'}
            onClick={onBackButton}
          >
            <ArrowLeftIcon width={'18'} />
            <span>Back to comments workflow</span>
          </button>
        )}
      </div>
      <div className={'flex items-center space-x-3 px-2'}>
        {userProfile?.feature_flags?.notifications === true && (
          <NotificationPopover />
        )}
        <HelpPopover />
        {user && (
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="flex rounded-sm bg-white px-2 py-1 text-sm hover:bg-gray-200 focus:ring-offset-2">
                <span className="sr-only">Open user menu</span>
                <div>{user.email}</div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                style={{
                  zIndex: 60,
                }}
                className="absolute right-0 z-50 mt-2 w-48 origin-top-right divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="px-3 py-3">
                  <p className="text-xs text-gray-400">Organisation</p>
                  <p className="truncate text-sm font-medium text-gray-900">
                    {userProfile?.organisation?.name}
                  </p>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`/settings`}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'z-50 block w-full px-3 py-2 text-left text-sm text-gray-700 hover:cursor-pointer'
                        )}
                      >
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'z-50 block w-full px-3 py-2 text-left text-sm text-gray-700 hover:cursor-pointer'
                        )}
                      >
                        Log out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  )
}

export default NavHeader
