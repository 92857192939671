import React, { useCallback } from 'react'
import {
  setModal,
  setProjectSidebarOpen,
} from '../../../../redux/application-slice'
import { FolderIcon } from '@heroicons/react/24/outline'
import ProjectOptionsMenu from '../project-options-menu'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES } from '../../../modals/modal-controller'
import LoadingCircle from '../../../loading/loading-circle'
import { Project } from '../../../../shared/interfaces/project/project.interface'
import UseUrlNavigate from '../../../../hooks/use-url-navigate'
import { setCurrentPage } from '../../../../redux/viewer-slice'

interface ProjectButtonProps {
  project: Project
}

function ProjectButton({ project }: ProjectButtonProps) {
  const { navigateUrl } = UseUrlNavigate()

  const dispatch = useDispatch()

  const onProjectOpened = useCallback(() => {
    if (!project?.uuid) {
      return
    }
    navigateUrl(project?.uuid)
    dispatch(setProjectSidebarOpen(false))
    dispatch(setCurrentPage(1))
  }, [dispatch, project, navigateUrl])

  const onProjectDelete = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.DELETE_PROJECT, project }))
  }, [dispatch, project])

  return (
    <div className={`flex w-full items-center rounded-r text-sm`}>
      <button
        data-cy={`project-open-button-${project.id}`}
        key={project.id}
        onClick={onProjectOpened}
        className={`group flex w-full cursor-pointer items-center px-1 text-left font-medium`}
        tabIndex={0}
      >
        <FolderIcon
          className={`mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500`}
          aria-hidden="true"
        />
        <div
          aria-label={`Navigate to ${project.title}`}
          className="flex h-8 w-full items-center justify-between"
        >
          <div className="w-36 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
            {project.title}
          </div>
          <div>
            <LoadingCircle
              isSpinning={!project.uuid}
              className="mr-2 h-5 w-5 animate-spin fill-blue-600 text-gray-200"
            />
          </div>
        </div>
      </button>
      <ProjectOptionsMenu project={project} onDelete={onProjectDelete} />
    </div>
  )
}

export default ProjectButton
