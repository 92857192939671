import React, { useCallback, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DocumentChange } from '../../../../shared/interfaces/project/document/changes/document-change.interface'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Quads } from '../../../../shared/interfaces/quads.interface'
import { setSelectedSources } from '../../../../redux/viewer-slice'
import DocumentReference from '../../../document-reference'
import { DocumentViewerContext } from '../../../../contexts/document-viewer-instance-context'
import { useBackButton } from '../../../../hooks/use-back-button'

interface AddendaSegmentViewerCardSourceButtonProps {
  documentChange: DocumentChange
}

const AddendaSegmentViewerCardSourceButton: React.FC<
  AddendaSegmentViewerCardSourceButtonProps
> = ({ documentChange }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const onClickSource = useCallback(() => {
    const currentPage = documentViewer?.getCurrentPage()
    const highlightID = uuidv4()
    const documentChangePage = documentChange.source.page
    const quads: Quads[] = documentChange?.source?.quads
    if (!currentPage) {
      return
    }
    addToNavigationHistoryAndNavigate(
      'documents',
      'supplementary-conditions',
      documentChange?.source?.document?.uuid ?? ''
    )

    searchParams.set('page', documentChangePage?.toString())
    setSearchParams(searchParams)

    dispatch(
      setSelectedSources([
        {
          id: highlightID,
          page: documentChangePage,
          quads: quads ?? [],
          isPrimary: true,
        },
      ])
    )
  }, [
    documentViewer,
    documentChange,
    addToNavigationHistoryAndNavigate,
    searchParams,
    setSearchParams,
    dispatch,
  ])
  return (
    <DocumentReference
      documentTitle={documentChange?.source?.document?.title ?? ''}
      pageNumber={documentChange?.source?.page ?? null}
      onClick={onClickSource}
    />
  )
}
export default AddendaSegmentViewerCardSourceButton
