import { Popover } from '@headlessui/react'
import React, { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch } from 'react-redux'
import { setHelpOpen } from '../../redux/application-slice'

const HelpPopover: React.FC = () => {
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const { styles, attributes } = usePopper(referenceElement, panelRef)
  const dispatch = useDispatch()

  const onOpenHelp = useCallback(() => {
    window.$chatwoot.toggle('open')
    dispatch(setHelpOpen(false))
    referenceElement?.click()
  }, [dispatch, referenceElement])

  return (
    <Popover id="custom-label-popover" className="relative">
      <Popover.Button
        tabIndex={-1}
        ref={setReferenceElement}
        className="relative flex items-center justify-between gap-2 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
      >
        Help
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 w-96 rounded border bg-white text-gray-700 shadow"
      >
        <div className="flex flex-col text-sm">
          <div className="m-1 flex flex-col space-y-1 bg-gray-100 p-3">
            <div>
              Talk to support with live chat for the fastest response time
            </div>
            <button
              onClick={onOpenHelp}
              className="flex w-full justify-center text-center text-indigo-500 hover:text-indigo-300"
            >
              <span>Chat now</span>
            </button>
          </div>
          <div className="m-1 bg-gray-100 p-3 ">
            <span>Contact support via email: </span>
            <a
              className="inline w-full justify-center text-center text-indigo-500 hover:text-indigo-300"
              href="mailto:support@useprovision.com"
            >
              support@useprovision.com
            </a>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
export default HelpPopover
