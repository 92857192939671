import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectNavigationHistory,
  setNavigationHistory,
} from '../../redux/viewer-slice'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { useNavigate } from 'react-router-dom'
import { useBackButton } from '../../hooks/use-back-button'

function NavigationHistoryPane() {
  const navigationHistory = useSelector(selectNavigationHistory)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useBackButton()

  const onNavigationClick = useCallback(() => {
    const page = navigationHistory[navigationHistory.length - 1].page
    const route = navigationHistory[navigationHistory.length - 1].route
    dispatch(
      setNavigationHistory(
        navigationHistory.slice(0, navigationHistory.length - 1)
      )
    )
    if (route) {
      navigate(route)
    } else {
      documentViewer?.setCurrentPage(page, false)
    }
  }, [dispatch, navigationHistory, documentViewer, navigate])

  return navigationHistory && navigationHistory.length > 0 ? (
    <button
      className={
        'flex cursor-pointer gap-2 rounded bg-gray-100 p-1 text-sm text-gray-800 hover:bg-gray-200'
      }
      onClick={onNavigationClick}
    >
      <ArrowUturnLeftIcon width={18} />
      Page {navigationHistory[navigationHistory.length - 1].page}
    </button>
  ) : null
}

export default NavigationHistoryPane
