import { Popover } from '@headlessui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import {
  useGetNotificationsQuery,
  useGetProjectsQuery,
  useMarkNotificationsAsReadMutation,
} from '../../../redux/api-slice'
import { ProvisionNotification } from '../../../shared/interfaces/notification/notification.interface'
import { NavLink } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import NotificationPopoverNotification from './notification-popover-notification'

export interface NotificationRef {
  [key: string]: boolean
}

const NotificationPopover: React.FC = () => {
  dayjs.extend(relativeTime)
  const { currentData: notifications } = useGetNotificationsQuery(false, {
    pollingInterval: 10000,
  })
  const { currentData: projects } = useGetProjectsQuery(undefined)
  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation()
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const notificationsIsReadRef = React.useRef<NotificationRef>({})

  useEffect(() => {
    if (!notifications) {
      return
    }
    for (const notification of notifications) {
      if (!(notification.id in notificationsIsReadRef.current)) {
        notificationsIsReadRef.current[notification.id] = notification.is_read
      }
    }
  }, [notifications])

  const onClickNotification = useCallback(() => {
    if (!notifications) {
      return
    }
    if (isOpen) {
      for (const notification of notifications) {
        notificationsIsReadRef.current[notification.id] = true
      }
    }
    markNotificationsAsRead(notifications.map((n) => n.id))
    setIsOpen(!isOpen)
  }, [isOpen, notifications, markNotificationsAsRead])

  const onNavigateClick = useCallback(() => {
    referenceElement?.click()
  }, [referenceElement])

  const notificationPopoverContent = useMemo(() => {
    return (
      <div>
        {notifications?.map((notification: ProvisionNotification) => (
          <NotificationPopoverNotification
            notification={notification}
            projects={projects ?? []}
            key={`notification_${notification.id}`}
            onNavigateClick={onNavigateClick}
          />
        ))}
        {notifications?.length === 0 && (
          <div className="overflow-hidden truncate whitespace-nowrap px-4 py-4 text-center">
            No notifications
          </div>
        )}
        <div className={'flex w-full justify-center'}>
          <NavLink
            className="cursor-pointer py-1 text-center hover:underline"
            to={'/notifications'}
            onClick={onNavigateClick}
          >
            View All Notifications
          </NavLink>
        </div>
      </div>
    )
  }, [notifications, onNavigateClick, projects])

  return (
    <Popover>
      <Popover.Button
        tabIndex={-1}
        ref={setReferenceElement}
        onClick={onClickNotification}
        className="relative flex items-center justify-between gap-2 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
      >
        Notifications
        {(notifications?.filter((n) => !n.is_read).length ?? 0) > 0 && (
          <div className="flex h-[0.85rem] w-[0.85rem] items-center justify-center rounded-full bg-red-500 text-center text-[0.6rem] font-bold text-white">
            <div>{notifications?.filter((n) => !n.is_read).length}</div>
          </div>
        )}
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="absolute top-1 z-[60] w-96 cursor-pointer rounded border bg-white text-left text-xs shadow-lg"
      >
        {notificationPopoverContent}
      </Popover.Panel>
    </Popover>
  )
}

export default NotificationPopover
