import React, { useCallback, useContext, useEffect } from 'react'
import NavigationHistoryPane from '../../viewer/navigation-history-pane'
import DocumentViewerCursorMode from '../document-viewer-cursor-mode'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCursorTool,
  setCursorTool,
  setZoomLevel,
} from '../../../redux/viewer-slice'
import { DocumentViewerContext } from '../../../contexts/document-viewer-instance-context'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import PageCount from '../../page-count'
import DocumentViewerViewOptions from './document-viewer-view-options'

const DocumentViewerOptionsHeader: React.FC = () => {
  const { documentId } = useParams<{
    documentId: string
    projectId: string
  }>()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const cursorTool = useSelector(selectCursorTool)
  const onClickTextSelect = useCallback(() => {
    dispatch(setCursorTool('TextSelect'))
  }, [dispatch])

  const onClickPan = useCallback(() => {
    dispatch(setCursorTool('Pan'))
  }, [dispatch])

  const onZoomIncrease = useCallback(() => {
    const newLevel = Math.min((documentViewer?.getZoomLevel() ?? 0) + 0.15, 2)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  const onZoomDecrease = useCallback(() => {
    const newLevel = Math.max((documentViewer?.getZoomLevel() ?? 0) - 0.15, 0.3)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  useEffect(() => {
    if (cursorTool && documentViewer) {
      documentViewer?.setToolMode(documentViewer?.getTool(cursorTool))
    }
  }, [cursorTool, documentViewer])

  return (
    <div className={'flex items-center space-x-2'}>
      <div>
        <NavigationHistoryPane />
      </div>
      <DocumentViewerCursorMode
        documentId={documentId ?? ''}
        cursorTool={cursorTool}
        onClickPan={onClickPan}
        onClickTextSelect={onClickTextSelect}
      />
      <button
        disabled={!documentId}
        className={`rounded bg-gray-100 p-1.5 shadow-sm ${
          documentId ? 'hover:bg-white' : ''
        }`}
        onClick={onZoomIncrease}
      >
        <PlusIcon width={20} />
      </button>
      <button
        disabled={!documentId}
        className={`rounded bg-gray-100 p-1.5 shadow-sm ${
          documentId ? 'hover:bg-white' : ''
        }`}
        onClick={onZoomDecrease}
      >
        <MinusIcon width={20} />
      </button>
      <PageCount />
      <DocumentViewerViewOptions />
    </div>
  )
}
export default DocumentViewerOptionsHeader
