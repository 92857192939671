import React, { useCallback, useMemo, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import {
  useGetProjectPermissionsByIdQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { selectCommentWorkflowFilterStateQueryParams } from '../../redux/workflow-slice'
import { useCreateRevisionCommentMutation } from '../../redux/api/api-revisions-slice'
import { toast } from 'react-toastify'

interface RevisionCreateCommentComponentProps {
  revision: Revision
  onRevisionChange?: (page: number) => void
}

const RevisionCreateComment: React.FC<RevisionCreateCommentComponentProps> = ({
  revision,
  onRevisionChange,
}) => {
  const renderSuggestion = useCallback((_, __, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>
  }, [])

  const { data: profile } = useGetUserProfileQuery(undefined)
  const [comment, setComment] = useState<string>('')
  const [commentIsSaving, setCommentIsSaving] = useState<boolean>(false)
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const revisionQueryParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )

  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject?.id ?? skipToken
  )
  const [createRevisionComment] = useCreateRevisionCommentMutation()

  const onChangeComment = useCallback(
    (e) => {
      if (!comment) {
        onRevisionChange?.(revision.page ?? 1)
      }
      setComment(e.target.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [revision.page, comment, onRevisionChange]
  )

  const onSaveComment = useCallback(
    (e) => {
      e.stopPropagation()
      setCommentIsSaving(true)
      createRevisionComment({
        revision_id: revision.id,
        comment: comment,
        revisionQueryParams: revisionQueryParams,
        user: profile,
        documents: [currentDocument?.id ?? 0],
      })
        .unwrap()
        .then(() => {
          setCommentIsSaving(false)
          setComment('')
          toast.success('Comment saved successfully')
        })
    },
    [
      revisionQueryParams,
      createRevisionComment,
      revision.id,
      comment,
      profile,
      currentDocument?.id,
    ]
  )

  const onCancelComment = useCallback((e) => {
    e.stopPropagation()
    setComment('')
  }, [])

  const mentionsData = useMemo(() => {
    return (
      permissions?.users
        ?.filter?.((u) => u.email)
        ?.map((u) => {
          return {
            id: u.id,
            display: u.email,
          }
        }) ?? []
    )
  }, [permissions])

  return (
    <div className={'space-y-2 p-1'}>
      <MentionsInput
        data-cy="revision-comment-input"
        className={'mentions'}
        placeholder="Add a comment..."
        onChange={onChangeComment}
        value={comment}
        disabled={commentIsSaving}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd
          data={mentionsData}
          renderSuggestion={renderSuggestion}
        />
      </MentionsInput>
      {comment && (
        <div className={'flex justify-end gap-2'}>
          <button
            data-cy="revision-comment-cancel"
            type="button"
            className="bg-white hover:bg-gray-100 shadow-sm px-4 py-1 border rounded font-semibold text-sm self-start focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onCancelComment}
            disabled={commentIsSaving}
          >
            Cancel
          </button>
          <button
            data-cy="revision-comment-save"
            type="button"
            className="border-indigo-600 bg-indigo-600 hover:bg-indigo-500 shadow-sm px-4 py-1 border rounded font-semibold text-sm text-white self-start focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onSaveComment}
            disabled={commentIsSaving}
          >
            {commentIsSaving ? 'Saving...' : 'Save'}
          </button>
        </div>
      )}
    </div>
  )
}

export default RevisionCreateComment
