import React, { useCallback, useContext, useMemo } from 'react'
import { Definition } from '../../shared/interfaces/definition/definition.interface'
import { useDispatch } from 'react-redux'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { setTemporaryHighlight } from '../../redux/viewer-slice'
import { isRatio } from '../../utils/convert-quads'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import DocumentReference from '../document-reference'
import { useBackButton } from '../../hooks/use-back-button'

interface SidebarDefinitionButtonProps {
  definition: Definition
  documents: ProjectDocumentMetadata[]
}

const SidebarDefinitionButton: React.FC<SidebarDefinitionButtonProps> = ({
  definition,
  documents,
}) => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const { addToNavigationHistoryAndNavigate } = useBackButton()

  const definitionDocument = useMemo(() => {
    return documents?.find((d) => {
      return d.id.toString() === definition.document.toString()
    })
  }, [definition, documents])

  const onClickDefinition = useCallback(() => {
    const page = documentViewer?.getCurrentPage()
    if (!page || !definitionDocument?.uuid) {
      return
    }
    addToNavigationHistoryAndNavigate(
      'documents',
      'documents',
      definitionDocument?.uuid,
      definition.page
    )
    dispatch(
      setTemporaryHighlight([
        {
          page: definition.page,
          quads: definition.quads ?? [],
          pdfCoords: isRatio(definition.quads ?? []),
        },
      ])
    )
    setTimeout(() => {
      dispatch(setTemporaryHighlight(null))
    }, 2000)
  }, [
    documentViewer,
    definitionDocument,
    addToNavigationHistoryAndNavigate,
    definition.page,
    definition.quads,
    dispatch,
  ])

  return (
    <div key={`definition_${definition.id}`} className="text-left text-sm">
      <p className={'font-bold'}>{definition.text}</p>
      <p>{definition.description}</p>
      <span className={'text-xs text-gray-400'}>
        <DocumentReference
          onClick={onClickDefinition}
          documentTitle={definitionDocument?.title ?? ''}
          pageNumber={definition.page}
        />
      </span>
    </div>
  )
}

export default SidebarDefinitionButton
