import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectTabWidth,
  setApplicationSidebarOpen,
  setPreviousTabWidth,
} from '../redux/application-slice'
import { useTabWidth } from '../hooks/use-tab-width'
import { selectZoomLevel } from '../redux/viewer-slice'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'

const SidebarCollapse: React.FC = () => {
  const zoomLevel = useSelector(selectZoomLevel)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const { setHalfWidth, setDocumentSidebarBig } = useTabWidth()
  const tabWidth = useSelector(selectTabWidth)
  const dispatch = useDispatch()

  const onExpandWorkflowSidebar = useCallback(() => {
    dispatch(setApplicationSidebarOpen('right'))
  }, [dispatch])

  const onExpandSidebarsEqual = useCallback(() => {
    dispatch(setApplicationSidebarOpen(null))
    setHalfWidth()
  }, [dispatch, setHalfWidth])

  const onExpandDocumentSidebar = useCallback(() => {
    dispatch(setPreviousTabWidth(tabWidth))
    setTimeout(() => {
      documentViewer?.zoomTo(zoomLevel)
    }, 1)
    dispatch(setApplicationSidebarOpen('left'))
  }, [dispatch, documentViewer, zoomLevel, tabWidth])

  const onDocumentSidebarBig = useCallback(() => {
    dispatch(setApplicationSidebarOpen(null))
    setDocumentSidebarBig()
  }, [dispatch, setDocumentSidebarBig])

  return (
    <div className="flex space-x-1 h-7">
      <button
        onClick={onExpandWorkflowSidebar}
        aria-label="Expand workflow sidebar"
        className="flex items-center space-x-0.5 bg-gray-50 hover:bg-gray-300 px-1 rounded text-sm cursor-pointer fill-gray-50"
      >
        <svg
          width="1"
          height="13"
          viewBox="0 0 1 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.25"
            y="0.25"
            width="0.5"
            height="12.5"
            rx="0.25"
            fill="#EDEDED"
            stroke="black"
            strokeWidth="0.5"
          />
        </svg>

        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            className="fill-gray-50 hover:fill-gray-300"
            y="0.5"
            width="14"
            height="12"
            rx="0.5"
            strokeWidth="1.3"
            stroke="black"
          />
        </svg>
      </button>
      <button
        aria-label="Expand sidebars equal"
        onClick={onExpandSidebarsEqual}
        className="flex items-center space-x-0.5 bg-gray-50 hover:bg-gray-300 px-1 rounded text-sm cursor-pointer"
      >
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="7"
            height="12"
            rx="0.5"
            strokeWidth="1.3"
            fill="#EDEDED"
            stroke="black"
          />
        </svg>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="7"
            height="12"
            rx="0.5"
            strokeWidth="1.3"
            fill="#EDEDED"
            stroke="black"
          />
        </svg>
      </button>
      <button
        aria-label="Document sidebar big"
        onClick={onDocumentSidebarBig}
        className="flex items-center space-x-0.5 bg-gray-50 hover:bg-gray-300 px-1 rounded text-sm cursor-pointer"
      >
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="10"
            height="12"
            className="fill-gray-50 hover:fill-gray-300"
            rx="0.5"
            strokeWidth="1.3"
            stroke="black"
          />
        </svg>
        <svg
          width="5"
          height="13"
          viewBox="0 0 5 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="4"
            height="12"
            rx="0.5"
            strokeWidth="1.3"
            className="fill-gray-50 hover:fill-gray-300"
            stroke="black"
          />
        </svg>
      </button>
      <button
        aria-label="Expand document sidebar"
        onClick={onExpandDocumentSidebar}
        className="flex justify-center items-center space-x-0.5 bg-gray-50 hover:bg-gray-300 px-1 rounded h-7 text-sm cursor-pointer"
      >
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="7"
            height="12"
            rx="0.5"
            strokeWidth="1.3"
            className="fill-gray-50 hover:fill-gray-300"
            stroke="black"
          />
        </svg>
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.46875 2.4375L8.53125 6.5L4.46875 10.5625"
            stroke="black"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  )
}
export default SidebarCollapse
