import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { useGetDocumentTemplatesQuery } from '../../redux/api/document-change-template-api-slice'
import { DocumentChangeTemplateInterface } from '../../shared/interfaces/project/document/document-change/document-change-template.interface'
import { useSelector } from 'react-redux'
import { selectCCDCVersion } from '../../redux/editor-slice'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface DocumentChangeTemplateReferenceComboBoxProps {
  selectedReference: DocumentChangeTemplateInterface[]
  setSelectedReference: React.Dispatch<
    React.SetStateAction<DocumentChangeTemplateInterface[]>
  >
  selectedDocumentChange?: DocumentChange | null
  onChangeReferencedDocumentChangeTemplates: () => void
  startingValue?: string
}

export default function DocumentChangeTemplateReferenceComboBox({
  selectedReference,
  setSelectedReference,
  onChangeReferencedDocumentChangeTemplates,
  selectedDocumentChange,
  startingValue,
}: DocumentChangeTemplateReferenceComboBoxProps) {
  const [query, setQuery] = useState('')
  const ccdcVersion = useSelector(selectCCDCVersion)
  const { data } = useGetDocumentTemplatesQuery(ccdcVersion)
  const filteredDocumentChangeTemplates = useMemo(() => {
    return query === ''
      ? data
      : data?.filter((template) => {
          return template.clause_reference
            .toLowerCase()
            .includes(query.toLowerCase())
        })
  }, [data, query])

  useEffect(() => {
    if (selectedDocumentChange && data) {
      const documentChangeTemplates = data.filter((template) =>
        selectedDocumentChange.original_clause_reference.includes(
          template.clause_reference
        )
      )
      setSelectedReference(documentChangeTemplates)
    }
    if (startingValue) {
      setQuery(startingValue)
    }
  }, [data, selectedDocumentChange, setSelectedReference, startingValue])

  const onSelectReference = useCallback(
    (e: DocumentChangeTemplateInterface[]) => {
      setSelectedReference(e)
      onChangeReferencedDocumentChangeTemplates()
    },
    [setSelectedReference, onChangeReferencedDocumentChangeTemplates]
  )

  const onChangeQuery = useCallback((e) => {
    setQuery(e.target.value)
  }, [])

  const comboBoxDisplayValue = useCallback(
    (template: DocumentChangeTemplateInterface[]) => {
      return template.reduce((acc, t) => {
        if (acc) {
          acc += ', '
        }
        return (acc += t.clause_reference)
      }, '')
    },
    []
  )

  const referenceComboboxClassNames = useCallback(({ active }) => {
    return classNames(
      'relative cursor-default select-none py-2 pl-3 pr-9',
      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
    )
  }, [])

  return (
    <Combobox
      as="div"
      value={selectedReference}
      multiple
      onChange={onSelectReference}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={onChangeQuery}
          displayValue={comboBoxDisplayValue}
          placeholder={'Select original clause'}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredDocumentChangeTemplates &&
          filteredDocumentChangeTemplates.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredDocumentChangeTemplates?.map(
                (template: DocumentChangeTemplateInterface) => (
                  <Combobox.Option
                    key={template.id}
                    value={template}
                    className={referenceComboboxClassNames}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected ? 'font-semibold' : ''
                          )}
                        >
                          {template.clause_reference}
                          {' > '}
                          <span
                            className={classNames(
                              'text-xs text-gray-500',
                              active ? 'text-white' : ''
                            )}
                          >
                            {template.document_name}
                          </span>
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                )
              )}
            </Combobox.Options>
          )}
      </div>
    </Combobox>
  )
}
