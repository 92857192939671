import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCommentWorkflowFilterState,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import { CalendarIcon } from '@heroicons/react/24/outline'

const RevisionDateFilter: React.FC = () => {
  const commentWorkflowFilterState = useSelector(
    selectCommentWorkflowFilterState
  )
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const startDateRef = React.useRef<HTMLInputElement>(null)
  const endDateRef = React.useRef<HTMLInputElement>(null)
  const onFocusDatePicker = useCallback((e) => {
    try {
      e.target.type = 'datetime-local'
      e?.target?.showPicker()
    } catch (e) {
      // do nothing
    }
  }, [])

  const onChangeStartDate = useCallback(
    (e) => {
      setStartDate(e.target.value)
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          startDate: e.target.value ?? '',
        })
      )
    },
    [commentWorkflowFilterState, dispatch]
  )

  const onChangeEndDate = useCallback(
    (e) => {
      setEndDate(e.target.value)
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          endDate: e.target.value,
        })
      )
    },
    [commentWorkflowFilterState, dispatch]
  )

  const onClickClearDateFilters = useCallback(() => {
    dispatch(
      setCommentWorkflowFilterState({
        ...commentWorkflowFilterState,
        startDate: null,
        endDate: null,
      })
    )
    setStartDate('')
    setEndDate('')
    if (startDateRef?.current && endDateRef?.current) {
      startDateRef.current.type = 'text'
      endDateRef.current.type = 'text'
    }
  }, [commentWorkflowFilterState, dispatch])

  const onDateBlur = useCallback((e) => {
    if (!e.target.value) {
      e.target.type = 'text'
    }
  }, [])

  return (
    <div className="flex items-center space-x-2 rounded-md border p-1.5">
      <CalendarIcon
        className="h-5 w-5 text-gray-400"
        title="Filter by date of last activity"
      />
      <input
        ref={startDateRef}
        className="min-w-[176px] rounded border-0 p-1 text-xs tabular-nums text-gray-700 ring-1 ring-inset ring-gray-300"
        onFocus={onFocusDatePicker}
        aria-label="Date and time"
        value={startDate}
        onChange={onChangeStartDate}
        max={endDate}
        onBlur={onDateBlur}
        placeholder="Start date"
      />
      <div className="shrink overflow-hidden">to</div>
      <input
        ref={endDateRef}
        className="min-w-[176px] rounded border-0 p-1 text-xs tabular-nums text-gray-700 ring-1 ring-inset ring-gray-300"
        onFocus={onFocusDatePicker}
        aria-label="Date and time"
        value={endDate}
        min={startDate}
        onChange={onChangeEndDate}
        onBlur={onDateBlur}
        placeholder="End date"
      />
      <button
        className="shrink-0 rounded bg-white px-2 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={onClickClearDateFilters}
      >
        Clear dates
      </button>
    </div>
  )
}

export default RevisionDateFilter
