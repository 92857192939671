import { HandRaisedIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { BsCursorText } from 'react-icons/bs'
import { Tooltip } from 'react-tooltip'

interface DocumentViewerCursorModeProps {
  onClickTextSelect: () => void
  onClickPan: () => void
  documentId: string | null
  cursorTool: string
}

const DocumentViewerCursorMode: React.FC<DocumentViewerCursorModeProps> = ({
  onClickTextSelect,
  onClickPan,
  cursorTool,
  documentId,
}) => {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        disabled={!documentId}
        type="button"
        className={`relative inline-flex items-center rounded-l-md ${
          cursorTool === 'TextSelect' ? 'bg-white' : 'bg-gray-50'
        } px-3 py-1.5 text-sm font-semibold text-gray-900 hover:bg-white focus:z-10`}
        onClick={onClickTextSelect}
        data-tooltip-id="textselect-id"
        data-tooltip-content="Text select"
      >
        <BsCursorText width={20} />
        <Tooltip id={'textselect-id'} style={{ zIndex: 100 }} />
      </button>
      <button
        disabled={!documentId}
        type="button"
        className={`relative -ml-px inline-flex items-center rounded-r-md ${
          cursorTool === 'Pan' ? 'bg-white' : 'bg-gray-50'
        } px-3 py-1.5 text-sm font-semibold text-gray-900 hover:bg-white focus:z-10`}
        onClick={onClickPan}
        data-tooltip-id="pan-id"
        data-tooltip-content="Pan"
      >
        <HandRaisedIcon width={20} />
        <Tooltip id={'pan-id'} style={{ zIndex: 100 }} />
      </button>
    </div>
  )
}
export default DocumentViewerCursorMode
