//createSlice for application

import { createSlice } from '@reduxjs/toolkit'
import { ProjectDocument } from '../shared/interfaces/project/document/document.interface'
import { Project } from '../shared/interfaces/project/project.interface'
import {
  ChecklistTemplate,
  ChecklistView,
} from '../shared/interfaces/checklist-template.interface'
import { Organization } from '../shared/organization/organization.interface'
import { DocumentSegmentSearchParams } from '../shared/interfaces/project/document/segments/document-segment.interface'

export interface ProjectState {
  openDocuments: ProjectDocument[]
  currentProject: Project | null
  currentDocument: ProjectDocument | null
  currentDocumentStatus: string | null
  zoomLevel: number
  textSelected: string | null
  currentPage: number
  modal: {
    modal: string
  } | null
  showDocumentGrid: boolean
  newTemplate: ChecklistTemplate | null
  currentOrganization: Organization | null
  selectedChecklistView: ChecklistView | null
  v1Document: number | null
  projectSidebarOpen: boolean
  applicationSidebarOpen: 'left' | 'right' | null
  tabWidth: number
  commentViewerTabWidth: number | null
  previousTabWidth: number
  helpOpen: boolean
  secondaryCommentViewerTabWidth: number | null
  commentViewerCenterWidth: number | null
  currentCommentDocuments: ProjectDocument[] | null
  documentSegmentsParams: DocumentSegmentSearchParams | null
  documentSearchQuery: string | null
}

const initialState: ProjectState = {
  currentProject: null,
  currentDocument: null,
  currentDocumentStatus: null,
  openDocuments: [],
  zoomLevel: 1,
  textSelected: null,
  currentPage: 1,
  modal: null,
  showDocumentGrid: false,
  newTemplate: null,
  currentOrganization: null,
  selectedChecklistView: null,
  v1Document: null,
  projectSidebarOpen: false,
  applicationSidebarOpen: null,
  tabWidth: 500,
  previousTabWidth: 500,
  helpOpen: false,
  commentViewerTabWidth: null,
  secondaryCommentViewerTabWidth: null,
  commentViewerCenterWidth: null,
  currentCommentDocuments: null,
  documentSegmentsParams: null,
  documentSearchQuery: null,
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setCurrentDocument: (
      state,
      action: {
        payload: ProjectDocument | null
        type: string
      }
    ) => {
      state.currentDocument = action.payload
    },
    setOpenDocuments: (state, action) => {
      state.openDocuments = action.payload
    },
    addOpenDocument: (state, action) => {
      if (
        !state.openDocuments.find((doc) => doc.uuid === action.payload.uuid)
      ) {
        state.openDocuments.push(action.payload)
        localStorage.setItem('tabs', JSON.stringify(state.openDocuments))
      }
    },
    removeOpenDocumentById: (state, action) => {
      // log payload type
      state.openDocuments = state.openDocuments.filter(
        (doc) => doc.id !== action.payload
      )
      localStorage.setItem('tabs', JSON.stringify(state.openDocuments))
    },
    setModal: (state, action) => {
      state.modal = action.payload
    },
    setNewTemplate: (state, action) => {
      state.newTemplate = action.payload
    },
    setShowDocumentGrid: (state, action) => {
      state.showDocumentGrid = action.payload
    },
    setCurrentDocumentStatus: (state, action) => {
      state.currentDocumentStatus = action.payload
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload
    },
    setSelectedChecklistView: (state, action) => {
      state.selectedChecklistView = action.payload
    },
    setV1Document: (state, action) => {
      state.v1Document = action.payload
    },
    setProjectSidebarOpen: (state, action) => {
      state.projectSidebarOpen = action.payload
    },
    setApplicationSidebarOpen: (state, action) => {
      state.applicationSidebarOpen = action.payload
    },
    setTabWidth: (state, action) => {
      state.tabWidth = action.payload
    },
    setPreviousTabWidth: (state, action) => {
      state.previousTabWidth = action.payload
    },
    setCurrentCommentDocuments: (state, action) => {
      state.currentCommentDocuments = action.payload
    },
    setHelpOpen: (state, action) => {
      state.helpOpen = action.payload
    },
    setCommentViewerTabWidth: (state, action) => {
      state.commentViewerTabWidth = action.payload
    },
    setSecondaryCommentViewerTabWidth: (state, action) => {
      state.secondaryCommentViewerTabWidth = action.payload
    },
    setCommentViewerCenterWidth: (state, action) => {
      state.commentViewerCenterWidth = action.payload
    },
    setDocumentSegmentsParams: (state, action) => {
      state.documentSegmentsParams = action.payload
    },
    setDocumentSearchQuery: (
      state,
      action: {
        payload: string | null
        type: string
      }
    ) => {
      state.documentSearchQuery = action.payload
    },
  },
})
export const {
  setCurrentDocument,
  setCurrentProject,
  setOpenDocuments,
  addOpenDocument,
  setModal,
  setNewTemplate,
  removeOpenDocumentById,
  setShowDocumentGrid,
  setCurrentDocumentStatus,
  setCurrentOrganization,
  setSelectedChecklistView,
  setV1Document,
  setProjectSidebarOpen,
  setApplicationSidebarOpen,
  setTabWidth,
  setPreviousTabWidth,
  setHelpOpen,
  setCommentViewerTabWidth,
  setSecondaryCommentViewerTabWidth,
  setCommentViewerCenterWidth,
  setCurrentCommentDocuments,
  setDocumentSegmentsParams,
  setDocumentSearchQuery,
} = applicationSlice.actions

export const selectCurrentProject = (state: {
  application: { currentProject: Project | null }
}) => state.application.currentProject
export const selectCurrentDocument = (state: {
  application: { currentDocument: ProjectDocument | null }
}) => state.application.currentDocument
export const selectOpenDocuments = (state: {
  application: { openDocuments: string }
}) => state.application.openDocuments
export const selectModal = (state) => state.application.modal
export const selectNewTemplate = (state) => state.application.newTemplate
export const selectCurrentDocumentStatus = (state) =>
  state.application.currentDocumentStatus
export const selectShowDocumentGrid = (state: {
  application: { showDocumentGrid: boolean }
}) => state.application.showDocumentGrid
export const selectCurrentOrganization = (state: {
  application: { setCurrentOrganization: Organization }
}) => state.application.setCurrentOrganization
export const selectSelectedChecklistView = (state: {
  application: { selectedChecklistView: ChecklistView }
}) => state.application.selectedChecklistView
export const selectV1Document = (state: {
  application: { v1Document: number }
}) => state.application.v1Document
export const selectProjectSidebarOpen = (state: {
  application: { projectSidebarOpen: boolean }
}) => state.application.projectSidebarOpen
export const selectApplicationSidebarOpen = (state) =>
  state.application.applicationSidebarOpen
export const selectTabWidth = (state) => {
  return state.application.tabWidth
}
export const selectPreviousTabWidth = (state) =>
  state.application.previousTabWidth
export const selectHelpOpen = (state: { application: { helpOpen: boolean } }) =>
  state.application.helpOpen
export const selectCommentViewerTabWidth = (state: {
  application: { commentViewerTabWidth: number | null }
}) => state.application.commentViewerTabWidth
export const selectSecondaryCommentViewerTabWidth = (state: {
  application: { secondaryCommentViewerTabWidth: number | null }
}) => state.application.secondaryCommentViewerTabWidth
export const selectCommentViewerCenterWidth = (state: {
  application: { commentViewerCenterWidth: number | null }
}) => state.application.commentViewerCenterWidth
export const selectCurrentCommentDocuments = (state: {
  application: { currentCommentDocuments: ProjectDocument[] | null }
}) => state.application.currentCommentDocuments
export const selectDocumentSegmentsParams = (state: {
  application: {
    documentSegmentsParams: DocumentSegmentSearchParams | null
  }
}) => state.application.documentSegmentsParams
export const selectDocumentSearchQuery = (state: {
  application: { documentSearchQuery: string | null }
}) => state.application.documentSearchQuery

export const applicationReducer = applicationSlice.reducer
