import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetDocumentByUuidQuery,
  useGetTocByDocumentUuidQuery,
} from '../../redux/api-slice'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { selectCurrentPage } from '../../redux/viewer-slice'
import { useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import SidebarTOCButton from './sidebar-toc-button'

function SidebarToc() {
  const { documentId } = useParams()
  const currentPage = useSelector(selectCurrentPage)
  const { data: tocData, isLoading } = useGetTocByDocumentUuidQuery(
    documentId ? documentId : skipToken
  )
  const { data: document } = useGetDocumentByUuidQuery(
    documentId ? documentId : skipToken
  )
  const [tocQuery, setTocQuery] = useState('')

  const onSetToCQuery = useCallback((e) => {
    setTocQuery(e.target.value)
  }, [])

  if (
    document?.job_status === 'PENDING' ||
    document?.job_status === 'PROCESSING'
  ) {
    return (
      <div className={'flex h-full w-full items-start justify-center'}>
        <div
          className={
            'my-12 flex w-56 flex-col items-center gap-2 text-center text-gray-500'
          }
        >
          <SparklesIcon width={32} />
          <div className={'text-black'}>Creating Table of Contents</div>
          <div className={'text-sm'}>
            Provision AI is analysing your document to extract relevant
            information. This will only take a couple of minutes.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={'h-full w-full'}>
      {tocData && tocData.length === 0 && (
        <div className={'text-center text-sm italic text-gray-400'}>
          No table of contents found
        </div>
      )}
      {isLoading && (
        <div className={'text-center text-sm italic text-gray-400'}>
          Fetching table of contents...
        </div>
      )}
      {tocData && tocData.length > 0 && (
        <div className="flex h-full flex-col px-2">
          <div className="relative mb-2 mt-1 flex-initial items-center">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full resize-none rounded-md  border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder={'Filter table of contents'}
              value={tocQuery}
              onChange={onSetToCQuery}
            />
          </div>
          <div className="flex-auto overflow-auto px-2">
            {tocData
              .filter((item) =>
                item.title.toLowerCase().includes(tocQuery.toLowerCase())
              )
              .map((toc) => (
                <SidebarTOCButton
                  key={`${toc.title}${toc.page}`}
                  toc={toc}
                  currentPage={currentPage}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SidebarToc
