import React, { useCallback, useEffect, useState, useMemo } from 'react'

import { FolderIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import {
  ChangeProjectOrder,
  useGetProjectsQuery,
  useUpdateProjectOrderMutation,
} from '../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectProjectSidebarOpen,
  setCurrentDocument,
  setCurrentProject,
  setModal,
  setProjectSidebarOpen,
} from '../../redux/application-slice'
import { MODAL_TYPES } from '../modals/modal-controller'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectManagerDragDrop from './project-manager-drag-drop'
import { useHotkeys } from 'react-hotkeys-hook'
import { arrayMove } from '@dnd-kit/sortable'
import { Project } from '../../shared/interfaces/project/project.interface'
import { DragEndEvent } from '@dnd-kit/core'

interface ProjectManagerProps {
  notification?: boolean
}

const ProjectManager: React.FC<ProjectManagerProps> = ({ notification }) => {
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const projectSidebarOpen = useSelector(selectProjectSidebarOpen)

  const [filterQuery, setFilterQuery] = useState('')
  const navigate = useNavigate()

  const { projectId } = useParams()

  const {
    data: projects,
    isLoading: projectsIsLoading,
    isFetching: projectsIsFetching,
  } = useGetProjectsQuery(undefined)
  const [updateProjectOrder] = useUpdateProjectOrderMutation()

  useEffect(() => {
    if (projects && projectId && !projectsIsFetching) {
      const projectMatch = projects.find((p) => p.uuid === projectId)
      if (projectMatch) {
        dispatch(setCurrentProject(projectMatch))
      } else {
        navigate('/')
        dispatch(setCurrentDocument(null))
        dispatch(setCurrentProject(null))
      }
    }
  }, [projects, projectId, dispatch, navigate, projectsIsFetching])

  const sortProjects = useCallback(
    async (
      foundActiveProjectIndex: number | undefined,
      foundOverProjectIndex: number | undefined
    ) => {
      if (
        foundActiveProjectIndex === undefined ||
        foundActiveProjectIndex === -1 ||
        foundOverProjectIndex === undefined ||
        foundOverProjectIndex === -1
      ) {
        return
      }
      let reorderedProjects: Project[] = [...(projects ?? [])]
      reorderedProjects = arrayMove(
        reorderedProjects,
        foundActiveProjectIndex,
        foundOverProjectIndex
      )
      const projectIndexes = reorderedProjects
        .reverse()
        .reduce<ChangeProjectOrder>((acc, item, index) => {
          if (!item.id) {
            return acc
          }
          acc[item.id] = index
          return acc
        }, {})
      await updateProjectOrder(projectIndexes)
    },
    [projects, updateProjectOrder]
  )

  const handleDrop = useCallback(
    async (dropResult: DragEndEvent) => {
      const { active, over } = dropResult
      if (!active || !over) {
        return
      }

      const foundActiveProjectIndex = projects?.findIndex(
        (d) => d.uuid === active.id
      )
      const foundOverProjectIndex = projects?.findIndex(
        (d) => d.uuid === over.id
      )
      await sortProjects(foundActiveProjectIndex, foundOverProjectIndex)
    },
    [projects, sortProjects]
  )

  const onCreateProjectClick = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.CREATE_PROJECT }))
  }, [dispatch])

  const onSetFilterQuery = useCallback((e) => {
    setFilterQuery(e.target.value)
  }, [])

  const onCloseSidebar = useCallback(() => {
    dispatch(setProjectSidebarOpen(false))
  }, [dispatch])

  useHotkeys('esc', onCloseSidebar, [onCloseSidebar])

  const projectManagerContent = useMemo(() => {
    return (
      <>
        <button
          onClick={currentProject ? onCloseSidebar : undefined}
          className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 opacity-100 transition-opacity cursor-default"
        />
        <div
          className={
            'fixed z-50 flex h-[calc(100vh-2.4rem)] w-96 flex-col overflow-hidden bg-white'
          }
        >
          <div className={'border-b border-gray-100 px-2'}>
            <button
              type="button"
              className="flex justify-center items-center border-gray-300 bg-white hover:bg-gray-50 shadow-sm my-2 px-2.5 py-1.5 border rounded w-full font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onCreateProjectClick}
            >
              Create Project
            </button>
          </div>
          <div
            className={'flex items-center gap-1 border-b border-gray-100  px-2'}
          >
            <MagnifyingGlassIcon className={'h-5 w-5'} />
            <input
              type="text"
              name="search"
              id="search"
              value={filterQuery}
              onChange={onSetFilterQuery}
              placeholder={'Search projects'}
              className="flex-grow border-0 px-1 py-2 focus:ring-0"
            />
          </div>
          <div className={'custom-scroll flex-grow overflow-y-scroll px-2'}>
            {projects !== undefined &&
              projects.filter((project) =>
                project?.title
                  ?.toLocaleLowerCase()
                  .includes(filterQuery.toLocaleLowerCase())
              ).length === 0 && (
                <div className={'text-center text-sm italic text-gray-400'}>
                  {filterQuery
                    ? 'No projects match that filter'
                    : 'No projects'}
                </div>
              )}
            {projects && (
              <ProjectManagerDragDrop
                projects={projects}
                filterQuery={filterQuery}
                handleDrop={handleDrop}
              />
            )}
            {projectsIsLoading && (
              <>
                <div
                  className={
                    'group flex animate-pulse cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                >
                  <FolderIcon
                    className={'mr-3 h-6 w-6 flex-shrink-0 text-gray-500'}
                    aria-hidden="true"
                  />
                  <div className="flex-1 bg-gray-200 rounded-lg w-24 h-4 text-ellipsis whitespace-nowrap overflow-hidden" />
                </div>
                <div
                  className={
                    'group flex animate-pulse cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                >
                  <FolderIcon
                    className={'mr-3 h-6 w-6 flex-shrink-0 text-gray-500'}
                    aria-hidden="true"
                  />
                  <div className="flex-1 bg-gray-200 rounded-lg w-24 h-4 text-ellipsis whitespace-nowrap overflow-hidden" />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }, [
    filterQuery,
    projects,
    projectsIsLoading,
    handleDrop,
    onSetFilterQuery,
    onCreateProjectClick,
    onCloseSidebar,
    currentProject,
  ])

  return <div>{projectSidebarOpen ? projectManagerContent : null}</div>
}

export default ProjectManager
