import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  selectDocumentSearchQuery,
  setDocumentSearchQuery,
} from '../../redux/application-slice'

const DocumentSearchInput: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const documentSearch = useSelector(selectDocumentSearchQuery)
  const dispatch = useDispatch()
  const onChangeDocumentSearch = useCallback(
    (e) => {
      dispatch(setDocumentSearchQuery(e.target.value))
    },
    [dispatch]
  )

  const filterDocumentsBySearchQuery = useCallback(() => {
    if (!documentSearch) {
      searchParams.delete('search')
      setSearchParams(searchParams)
      dispatch(setDocumentSearchQuery(null))
      return
    }
    searchParams.set('search', documentSearch)
    setSearchParams(searchParams)
  }, [dispatch, documentSearch, searchParams, setSearchParams])

  const onClickSearch = useCallback(() => {
    filterDocumentsBySearchQuery()
  }, [filterDocumentsBySearchQuery])

  const onKeyPressSearch = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        filterDocumentsBySearchQuery()
      }
    },
    [filterDocumentsBySearchQuery]
  )
  return (
    <div className="flex items-center space-x-2">
      <input
        tabIndex={0}
        placeholder={'Search'}
        type="search"
        name="document_search"
        id="document_search"
        value={documentSearch ?? ''}
        onChange={onChangeDocumentSearch}
        onKeyDown={onKeyPressSearch}
        className="block rounded border-0 p-1 text-sm ring-1 ring-inset ring-gray-300 focus:ring-indigo-500"
      />
      <button
        onClick={onClickSearch}
        className={`rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
      >
        Search
      </button>
    </div>
  )
}
export default DocumentSearchInput
