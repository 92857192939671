import React, { useCallback } from 'react'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import DocumentChangeTemplateReferenceComboBox from './reference-combox'
import { DocumentChangeTemplateInterface } from '../../shared/interfaces/project/document/document-change/document-change-template.interface'

interface AmendmentEditorProps {
  localAmendment: DocumentChange
  setLocalAmendment: React.Dispatch<React.SetStateAction<DocumentChange | null>>
  referencedDocumentChangeTemplates: DocumentChangeTemplateInterface[]
  setReferencedDocumentChangeTemplates: React.Dispatch<
    React.SetStateAction<DocumentChangeTemplateInterface[]>
  >
  selectedDocumentChange: DocumentChange | null
}

const AmendmentEditor: React.FC<AmendmentEditorProps> = ({
  localAmendment,
  setLocalAmendment,
  setReferencedDocumentChangeTemplates,
  referencedDocumentChangeTemplates,
  selectedDocumentChange,
}) => {
  const onChangeReferencedDocumentChangeTemplates = useCallback(() => {
    //intentionally left blank
  }, [])

  const onChangeClause = useCallback(
    (e) => {
      setLocalAmendment((state) => {
        if (state) {
          return {
            ...state,
            original_clause: '',
            original_clause_reference: e.target.value,
          }
        } else {
          return state
        }
      })
    },
    [setLocalAmendment]
  )
  const onChangeChangeType = useCallback(
    (e) => {
      const change_type = e.target.value as
        | 'delete'
        | 'amend'
        | 'create'
        | 'replace'
      return setLocalAmendment({
        ...localAmendment,
        change_type,
        reconciled_clause:
          change_type === 'delete' ? '' : localAmendment.reconciled_clause,
      })
    },
    [localAmendment, setLocalAmendment]
  )
  const onChangeCreate = useCallback(
    (e) => {
      setLocalAmendment((state) => {
        if (state) {
          return {
            ...state,
            reconciled_clause: e.target.value,
          }
        } else {
          return state
        }
      })
    },
    [setLocalAmendment]
  )

  const onChangeAmend = useCallback(
    (e) => {
      setLocalAmendment((state) => {
        if (state) {
          return {
            ...state,
            reconciled_clause: e.target.value,
          }
        } else {
          return state
        }
      })
    },
    [setLocalAmendment]
  )

  return (
    <div className="space-y-2">
      <select
        name=""
        id=""
        value={localAmendment.change_type}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onChange={onChangeChangeType}
      >
        <option value="create">Create</option>
        <option value="amend">Amend</option>
        <option value="replace">Replace</option>
        <option value="delete">Delete</option>
      </select>
      {['replace', 'amend', 'delete'].includes(localAmendment.change_type) && (
        <div className={'space-y-2'}>
          <DocumentChangeTemplateReferenceComboBox
            onChangeReferencedDocumentChangeTemplates={
              onChangeReferencedDocumentChangeTemplates
            }
            setSelectedReference={setReferencedDocumentChangeTemplates}
            selectedReference={referencedDocumentChangeTemplates}
            selectedDocumentChange={selectedDocumentChange}
          />
          <textarea
            name=""
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={'Clause content'}
            id=""
            cols={30}
            rows={10}
            onChange={onChangeAmend}
            value={localAmendment.reconciled_clause}
          />
        </div>
      )}
      {localAmendment.change_type === 'create' && (
        <>
          <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={'Enter clause reference'}
            onChange={onChangeClause}
            value={localAmendment.original_clause_reference}
          />
          <textarea
            name=""
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={'Clause content'}
            id=""
            cols={30}
            rows={10}
            onChange={onChangeCreate}
            value={localAmendment.reconciled_clause ?? ''}
          />
        </>
      )}
    </div>
  )
}

export default AmendmentEditor
