import React, { useCallback } from 'react'
import { selectCurrentDocument } from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectTextSelected, setTextSelected } from '../../redux/viewer-slice'
import { BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import { setNewAmendment } from '../../redux/editor-slice'
import { useQuads } from '../../hooks/use-quads'

interface ContextMenuProps {
  positionX: string
  positionY: string
}

const EditorContextMenu: React.FC<ContextMenuProps> = ({
  positionX,
  positionY,
}) => {
  const document = useSelector(selectCurrentDocument)
  const textSelected = useSelector(selectTextSelected)
  const dispatch = useDispatch()
  const { mappedQuads } = useQuads()

  const onMouseDown = useCallback(
    (e) => {
      if (!textSelected?.pageNumber || !document) {
        return
      }
      dispatch(
        setNewAmendment({
          source: {
            page: textSelected.pageNumber,
            document_uuid: document.uuid,
            quads: mappedQuads ?? [],
          },
          original_clause: '',
          original_clause_reference: '',
          change_type: 'create',
          reconciled_clause: '',
          explanation: '',
        })
      )
      dispatch(setTextSelected(null))
      e.stopPropagation()
      e.preventDefault()
    },
    [dispatch, document, mappedQuads, textSelected?.pageNumber]
  )

  return (
    <div
      className={'absolute z-50 flex rounded border bg-white shadow-lg'}
      style={{
        top: positionY,
        left: positionX,
      }}
    >
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded hover:bg-gray-100'
        }
        onMouseDown={onMouseDown}
        data-tooltip-id="revision-id"
        data-tooltip-content="Create CCDC Amendment"
      >
        <BarsArrowUpIcon width={20} />
        <Tooltip id={'revision-id'} style={{ zIndex: 100 }} />
      </button>
    </div>
  )
}

export default EditorContextMenu
