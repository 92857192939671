import React, { useState, useCallback } from 'react'
import LoadingCircle from '../../loading/loading-circle'
import { useInviteUserToProjectMutation } from '../../../redux/api-slice'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Project } from '../../../shared/interfaces/project/project.interface'

interface SharingModalOrgInviteButtonProps {
  user: ProvisionUser
  project: Project
}

const SharingModalOrgInviteButton: React.FC<
  SharingModalOrgInviteButtonProps
> = ({ user, project }) => {
  const posthog = usePostHog()
  const [inviteUsers] = useInviteUserToProjectMutation()
  const [orgUserSubmitting, setOrgUserSubmitting] = useState(false)
  const onSubmitUser = useCallback(async () => {
    if (!project?.id) {
      return
    }
    setOrgUserSubmitting(true)
    await inviteUsers({
      projectID: project?.id,
      emails: [{ email: user?.email ?? '' }],
    }).unwrap()
    setOrgUserSubmitting(false)
    posthog?.capture(POSTHOG.invite_users, {
      project_uuid: project?.id,
    })
  }, [project?.id, inviteUsers, posthog, user?.email])
  return (
    <button
      onClick={onSubmitUser}
      className="bg-indigo-600 hover:bg-indigo-500 shadow-sm px-2 py-1 rounded font-semibold text-white text-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {orgUserSubmitting ? (
        <LoadingCircle
          className="mt-0.5 w-4 h-4 text-gray-200 animate-spin fill-blue-600"
          isSpinning={orgUserSubmitting}
        />
      ) : (
        <div>Add</div>
      )}
    </button>
  )
}

export default SharingModalOrgInviteButton
