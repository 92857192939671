import React, { useMemo } from 'react'
import AIChatSourceMessageButton from './ai-chat-source-message-button'
import { ChatSessionMessageSource } from '../../../shared/interfaces/chat/chat-history.interface'

interface AIChatMessageWithSourcesProps {
  chatHistoryMessage: {
    message: string
    message_sources?: ChatSessionMessageSource[]
  }
}

const AIChatSourceMessageButtonV2: React.FC<AIChatMessageWithSourcesProps> = ({
  chatHistoryMessage,
}) => {
  let { message, message_sources = [] } = chatHistoryMessage
  message = message?.trim() ?? ''

  const elements = useMemo(() => {
    // Regex to find placeholders like [1], [2], [3], etc.
    const placeholderRegex = /\[\d+\]/g
    const placeholders = [...message.matchAll(placeholderRegex)]
    const result: JSX.Element[] = []
    let lastIndex = 0

    placeholders.forEach((match, index) => {
      const placeholderIndex = parseInt(match[0].slice(1, -1)) - 1 // Convert [1] to 0, [2] to 1, etc.
      const startIndex = match.index || 0

      result.push(
        <span key={`text-${index}`}>
          {message.slice(lastIndex, startIndex)}
        </span>
      )

      if (placeholderIndex >= 0 && placeholderIndex < message_sources.length) {
        const source = message_sources[placeholderIndex]
        result.push(
          <AIChatSourceMessageButton
            key={source.id}
            chatSessionMessageSource={source}
            sources={message_sources}
            sourceIndex={placeholderIndex}
            indexOfParagraphMatch={0}
          />
        )
      } else {
        result.push(<span key={`placeholder-${index}`}>{match[0]}</span>)
      }

      lastIndex = startIndex + match[0].length
    })

    result.push(
      <span key={`text-${placeholders.length}`}>
        {message.slice(lastIndex)}
      </span>
    )

    return result
  }, [message, message_sources])

  return <>{elements}</>
}

export default AIChatSourceMessageButtonV2
