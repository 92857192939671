import React, { useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import WorkflowPopover from './workflows/workflow-popover/workflow-popover'
import SidebarCollapse from './sidebar-collapse'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigateWorkflow } from '../hooks/use-navigate-workflow'
import { workflows } from './workflows/workflow-popover/workflow.data'
import { POSTHOG } from '../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../redux/application-slice'
import { ErrorBoundary } from '@sentry/react'
import ErrorMessage from './workflows/workflow-components/error-message'

interface WorkflowSidebarProps {
  width: number | string
}

const WorkflowSidebar: React.FC<WorkflowSidebarProps> = ({ width }) => {
  const { navigateWorkflow } = useNavigateWorkflow()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)

  const onNavigateDocuments = useCallback(() => {
    navigateWorkflow(workflows[0])
    posthog?.capture(POSTHOG.documents_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateRiskReview = useCallback(() => {
    navigateWorkflow(workflows[1])
    posthog?.capture(POSTHOG.risk_review_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateChat = useCallback(() => {
    navigateWorkflow(workflows[2])
    posthog?.capture(POSTHOG.chat_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateComments = useCallback(() => {
    navigateWorkflow(workflows[3])
    posthog?.capture(POSTHOG.comments_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateFilters = useCallback(() => {
    navigateWorkflow(workflows[4])
    posthog?.capture(POSTHOG.clause_filtering_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateSupplementary = useCallback(() => {
    navigateWorkflow(workflows[5])
    posthog?.capture(POSTHOG.supplementary_conditions_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  useHotkeys('1', onNavigateDocuments)
  useHotkeys('2', onNavigateRiskReview)
  useHotkeys('3', onNavigateChat)
  useHotkeys('4', onNavigateComments)
  useHotkeys('5', onNavigateFilters)
  useHotkeys('6', onNavigateSupplementary)
  return (
    <div
      className="flex h-full w-full flex-col items-stretch"
      style={{
        width,
      }}
    >
      <div className="flex h-10 justify-between p-1">
        <WorkflowPopover />
        <SidebarCollapse />
      </div>
      <ErrorBoundary fallback={ErrorMessage}>
        <Outlet />
      </ErrorBoundary>
    </div>
  )
}

export default WorkflowSidebar
