import React, { forwardRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentSegment } from '../../../../shared/interfaces/project/document/segments/document-segment.interface'
import {
  selectHasZoomedRecently,
  selectSelectedAddendum,
  setSelectedAddendum,
} from '../../../../redux/viewer-slice'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import AddendaSegmentViewerCardSourceButton from './document-viewer-addenda-card-source-button'
import Diff from '../../../workflows/comment-table/diff'

interface RevisionCreateSidelingProps {
  addendaSegment?: DocumentSegment
  top?: string
}

const pattern = /(\r\n|\n|\r|\([a-z]\)|[a-z]\)|\([iv]+\))/gm

const DocumentViewerAddendaCard = forwardRef<
  HTMLDivElement,
  RevisionCreateSidelingProps
>(({ addendaSegment, top }, ref) => {
  const hasZoomedRecently = useSelector(selectHasZoomedRecently)
  const selectedAddendum = useSelector(selectSelectedAddendum)
  const dispatch = useDispatch()

  const toggleExpanded = useCallback(() => {
    const addendum =
      selectedAddendum === addendaSegment?.id
        ? null
        : addendaSegment?.id ?? null
    dispatch(setSelectedAddendum(addendum))
  }, [addendaSegment?.id, dispatch, selectedAddendum])

  return (
    <div
      data-id={addendaSegment?.id}
      ref={ref}
      id={addendaSegment?.id?.toString()}
      className={`addenda-sideling revision-sideling absolute mb-2 ml-4 pr-2 ${hasZoomedRecently ? '' : 'transition-all'}`}
      style={{
        zIndex: 51,
        top,
        opacity: hasZoomedRecently ? 0 : 1,
      }}
    >
      <div
        onClick={toggleExpanded}
        className={`relative w-96 rounded border border-gray-300 bg-white text-left text-sm ${selectedAddendum === addendaSegment?.id ? 'shadow-lg' : ''}`}
      >
        {addendaSegment?.sources?.map((documentChange) => (
          <>
            <button
              onClick={toggleExpanded}
              className="flex w-full items-center justify-between p-2"
            >
              <div className="flex space-x-2">
                <span className="font-bold">Modified</span>
                <span>{documentChange.original_clause_reference} </span>
              </div>
              <div>
                {selectedAddendum === addendaSegment?.id ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </div>
            </button>
            {selectedAddendum === addendaSegment?.id ? (
              <div className="flex flex-col space-y-1">
                <div className="border border-x-2 p-2">
                  <Diff
                    inputA={
                      addendaSegment?.original_text &&
                      documentChange?.change_type !== 'create'
                        ? addendaSegment?.original_text.replace(pattern, ' ')
                        : ''
                    }
                    inputB={
                      addendaSegment?.text
                        ? addendaSegment?.text.replace(pattern, ' ')
                        : ''
                    }
                  />
                </div>
                <div className="p-2">
                  <div className="font-bold">Explanation</div>
                  {documentChange?.explanation}
                </div>
                <div className="w-full p-2 text-xs text-gray-700">
                  <span className="font-bold">Modified by addenda</span>
                  <AddendaSegmentViewerCardSourceButton
                    documentChange={documentChange}
                  />
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </div>
  )
})

DocumentViewerAddendaCard.displayName = 'RevisionCreateSideling'

export default DocumentViewerAddendaCard
