import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { SelectedSource, setSelectedSources } from '../../../redux/viewer-slice'
import { v4 as uuidv4 } from 'uuid'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { ChatSessionMessageSource } from '../../../shared/interfaces/chat/chat-history.interface'
import { useGetUserProfileQuery } from '../../../redux/api-slice'

interface AIChatSourceButtonProps {
  sourceDocument?: ProjectDocumentMetadata
  source: ChatSessionMessageSource
  sources: ChatSessionMessageSource[]
  sourceIndex: number
  indexOfParagraphMatch: number
}

const AIChatSourceButton: React.FC<AIChatSourceButtonProps> = ({
  sourceDocument,
  source,
  sourceIndex,
  indexOfParagraphMatch,
  sources,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { data: user } = useGetUserProfileQuery(undefined)
  const sources_v2_flag = user?.feature_flags?.sources_v2_chat
  const onClickSource = useCallback(() => {
    navigate(`/${currentProject?.uuid}/chat/${sourceDocument?.uuid}`)
    const page = source.source.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)
    const distinctSources = sources.reduce<ChatSessionMessageSource[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment
        if (!acc.find((c) => c.document_segment === document_segment_id)) {
          acc.push(citation)
        }
        return acc
      },
      []
    )
    const selectedSources: SelectedSource[] = []
    for (const chatSource of distinctSources) {
      const highlightID = uuidv4()

      selectedSources.push({
        id: highlightID,
        page: parseInt(chatSource.source.toString()),
        quads: chatSource.source_quads,
        isPrimary: chatSource.id === source.id,
      })
    }
    dispatch(setSelectedSources(selectedSources))
  }, [
    currentProject,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
    source,
    sourceDocument,
    sources,
  ])

  return sources_v2_flag ? (
    <button
      onClick={onClickSource}
      className="mr-0.5 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-gray-300 px-1 text-center text-[0.55rem] text-gray-700 hover:bg-gray-500 hover:text-white"
    >
      <div>{sourceIndex + 1}</div>
    </button>
  ) : (
    <button
      onClick={onClickSource}
      className="mr-0.5 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-gray-300 px-1 text-center text-[0.55rem] text-gray-700 hover:bg-gray-500 hover:text-white"
    >
      <div>{sourceIndex + indexOfParagraphMatch + 1}</div>
    </button>
  )
}

export default AIChatSourceButton
