import React, { useCallback } from 'react'
import { ProjectRiskList } from '../../../shared/interfaces/project/risk/risk-inteface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'

interface RiskReviewListButtonProps {
  projectRiskList: ProjectRiskList | null
  setSelectedRiskList: React.Dispatch<
    React.SetStateAction<ProjectRiskList | null>
  >
  selectedRiskList: ProjectRiskList | null
}

const RiskReviewListButton: React.FC<RiskReviewListButtonProps> = ({
  projectRiskList,
  setSelectedRiskList,
  selectedRiskList,
}) => {
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const onClickProjectRiskListButton = useCallback(() => {
    setSelectedRiskList(projectRiskList)
    posthog?.capture(POSTHOG.risk_review_list_opened, {
      project_uuid: currentProject?.uuid,
      risk_list_name: projectRiskList?.name,
    })
  }, [setSelectedRiskList, projectRiskList, posthog, currentProject])

  return (
    <button
      onClick={onClickProjectRiskListButton}
      className={`flex justify-start rounded px-3 py-2 text-left hover:bg-gray-50 ${
        selectedRiskList?.id === projectRiskList?.id ? 'bg-gray-50' : ''
      }`}
    >
      {projectRiskList?.name ?? 'All risks'}
    </button>
  )
}

export default RiskReviewListButton
