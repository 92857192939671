import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectModal,
  setModal,
} from '../../redux/application-slice'
import { useCreateChatFeedbackMutation } from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import LoadingCircle from '../loading/loading-circle'
import { toast } from 'react-toastify'
import { useSubmitProjectRiskFeedbackMutation } from '../../redux/api/project-risk-api-slice'

interface AIChatFeedbackModalProps {
  open: boolean
}

function AIChatFeedbackModal({ open }: AIChatFeedbackModalProps) {
  const [submitting, setSubmitting] = React.useState(false)
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const modal = useSelector(selectModal)
  const [feedback, setFeedback] = useState<string>('')
  const [submitChatFeedback] = useCreateChatFeedbackMutation()
  const [submitProjectRiskFeedback] = useSubmitProjectRiskFeedbackMutation()
  const currentProject = useSelector(selectCurrentProject)

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setFeedback('')
    setSubmitting(false)
  }, [dispatch, setFeedback, setSubmitting])

  const onSubmit = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    if (modal?.data?.project_risk) {
      await submitProjectRiskFeedback({
        is_positive: modal?.data?.positive,
        comment: feedback,
        project_risk: modal?.data?.project_risk,
      })
    }
    if (modal?.data?.query && modal?.data?.answer) {
      await submitChatFeedback({
        rating_positive: modal?.data?.positive,
        rating_comment: feedback ? feedback : null,
        project: currentProject?.id,
        query: modal?.data?.query,
        answer: modal?.data?.answer,
      })
      posthog?.capture(POSTHOG.chat_feedback_submitted, {
        project_uuid: currentProject?.uuid,
        rating_positive: modal?.data?.positive,
      })
    }
    toast.success('Thank you for your feedback!')
    onClose()
  }, [
    posthog,
    submitChatFeedback,
    submitProjectRiskFeedback,
    currentProject,
    onClose,
    modal,
    feedback,
    submitting,
  ])

  const onSetFeedback = useCallback((e) => {
    setFeedback(e.target.value)
  }, [])

  const onFeedbackKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubmit()
      }
    },
    [onSubmit]
  )

  const feedbackDialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white px-3 pb-2 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div>
          <div>
            <Dialog.Title
              as="h1"
              className="mb-10 text-base font-semibold leading-6 text-gray-900"
            >
              Feedback
            </Dialog.Title>
            <div className="mb-2 flex text-sm">
              Rating:{' '}
              {modal?.data?.positive ? (
                <HandThumbUpIcon className="ml-1 mt-0.5 h-4 w-4 cursor-pointer hover:fill-gray-300" />
              ) : (
                <HandThumbDownIcon className="ml-1 mt-0.5 h-4 w-4 cursor-pointer hover:fill-gray-300" />
              )}
            </div>
            <div className="mt-1">
              <textarea
                onKeyDown={onFeedbackKeyDown}
                onChange={onSetFeedback}
                value={feedback}
                style={{ minHeight: 125, maxHeight: '70vh' }}
                tabIndex={0}
                placeholder="Enter additional feedback here"
                name="additional_feedback"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
              onClick={onSubmit}
            >
              <LoadingCircle
                className="mr-2 mt-0.5 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                isSpinning={submitting}
              />
              Submit Feedback
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [
    feedback,
    modal,
    onClose,
    onSetFeedback,
    onSubmit,
    submitting,
    onFeedbackKeyDown,
  ])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {feedbackDialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AIChatFeedbackModal
