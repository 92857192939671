import React from 'react'
const DocumentViewerSkeleton = () => {
  return (
    <div className="m-auto flex h-[95%] w-3/5 items-center justify-center bg-gray-200 p-1">
      <div className="flex h-full w-full flex-col items-center justify-center space-y-3 bg-white px-6 py-3">
        {Array(1)
          .fill(0)
          .map((_, index) => index)
          .map((loadingCard) => (
            <React.Fragment key={`top_content_${loadingCard}`}>
              <div className="mr-2 h-12 w-1/2 animate-pulse rounded border-l bg-gray-300 text-xs text-gray-500" />
              <div className="h-2" />
              <div className=" mr-2 h-6 w-full animate-pulse rounded border-l bg-gray-300 p-4 text-xs text-gray-500" />
            </React.Fragment>
          ))}
        {Array(5)
          .fill(0)
          .map((_, index) => index)
          .map((loadingCard) => (
            <React.Fragment key={`remaining_content_${loadingCard}`}>
              <div className="mr-2 h-12 w-full animate-pulse rounded border-l bg-gray-300 text-xs text-gray-500" />
              <div className="h-2" />
              <div className=" mr-2 h-6 w-full animate-pulse rounded border-l bg-gray-300 p-4 text-xs text-gray-500" />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default DocumentViewerSkeleton
