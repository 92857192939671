import React, { useCallback, useEffect, useState } from 'react'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import AmendmentEditor from '../qa-editor/amendment-editor'
import { DocumentChangeTemplateInterface } from '../../shared/interfaces/project/document/document-change/document-change-template.interface'
import { setNewAmendment } from '../../redux/editor-slice'
import {
  useCreateDocumentChangeMutation,
  useDeleteDocumentChangeMutation,
  useUpdateDocumentChangeMutation,
} from '../../redux/api/document-change-template-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'

interface SupplementaryConditionsQACardProps {
  documentChange: DocumentChange | null
  setSelectedDocumentChange: (dc: DocumentChange | null) => void
}

const SupplementaryConditionsQAEditor: React.FC<
  SupplementaryConditionsQACardProps
> = ({ documentChange, setSelectedDocumentChange }) => {
  const [localAmendment, setLocalAmendment] = useState<DocumentChange | null>(
    documentChange ?? null
  )

  const [
    referencedDocumentChangeTemplates,
    setReferencedDocumentChangeTemplates,
  ] = useState<DocumentChangeTemplateInterface[]>([])
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)

  const [createDocumentChange] = useCreateDocumentChangeMutation()
  const [updateDocumentChange] = useUpdateDocumentChangeMutation()
  const [deleteDocumentChange] = useDeleteDocumentChangeMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    setLocalAmendment((state) => {
      const clauses = referencedDocumentChangeTemplates.reduce(
        (acc, t) => {
          if (acc.clause_content) {
            acc.clause_content += '\n\n'
          }
          if (acc.clause_reference) {
            acc.clause_reference += ', '
          }
          return {
            clause_reference: (acc.clause_reference += t.clause_reference),
            clause_content: (acc.clause_content += t.clause_content),
          }
        },
        { clause_reference: '', clause_content: '' }
      )
      if (state) {
        const newState = {
          ...state,
          original_clause: clauses.clause_content ?? '',
          reconciled_clause:
            localAmendment?.change_type === 'delete'
              ? ''
              : clauses.clause_content ?? '',
          document: currentDocument?.id,
        }
        if (clauses.clause_reference) {
          newState.original_clause_reference = clauses.clause_reference
        }
        return newState
      } else {
        return state
      }
    })
  }, [
    currentDocument?.id,
    localAmendment?.change_type,
    referencedDocumentChangeTemplates,
    setLocalAmendment,
  ])

  const onDocumentChangeSave = useCallback(() => {
    if (localAmendment) {
      if (localAmendment?.id) {
        updateDocumentChange({
          change_type: localAmendment.change_type,
          original_clause: localAmendment.original_clause ?? '',
          original_clause_reference: localAmendment.original_clause_reference,
          reconciled_clause: localAmendment.reconciled_clause,
          document: localAmendment.document,
          status: localAmendment.status,
          id: localAmendment.id,
          projectUUID: currentProject?.uuid,
          qa_checked: true,
        })
          .unwrap()
          .then((data) => setSelectedDocumentChange(data))
      } else {
        createDocumentChange({
          ...localAmendment,
          qa_checked: true,
          document: currentDocument?.id,
        })
      }
      dispatch(setNewAmendment(null))
    }
  }, [
    dispatch,
    createDocumentChange,
    localAmendment,
    updateDocumentChange,
    currentProject?.uuid,
    currentDocument?.id,
    setSelectedDocumentChange,
  ])

  const onCancelAmendment = useCallback(() => {
    setSelectedDocumentChange(null)
    dispatch(setNewAmendment(null))
  }, [dispatch, setSelectedDocumentChange])

  const onDeleteAmendment = useCallback(() => {
    if (documentChange) {
      deleteDocumentChange(documentChange)
      setSelectedDocumentChange(null)
      setLocalAmendment(null)
    }
  }, [
    documentChange,
    deleteDocumentChange,
    setSelectedDocumentChange,
    setLocalAmendment,
  ])

  useEffect(() => {
    setLocalAmendment(documentChange)
  }, [documentChange])

  return (
    <div className={'rounded border p-2'}>
      {localAmendment ? (
        <>
          <AmendmentEditor
            localAmendment={localAmendment}
            setLocalAmendment={setLocalAmendment}
            referencedDocumentChangeTemplates={
              referencedDocumentChangeTemplates
            }
            setReferencedDocumentChangeTemplates={
              setReferencedDocumentChangeTemplates
            }
            selectedDocumentChange={documentChange}
          />
          <div className={'flex w-full justify-between gap-2 py-2'}>
            <button
              type="button"
              className="justify-self-start rounded border bg-white px-4 py-1 text-sm font-semibold hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={onDeleteAmendment}
            >
              Delete
            </button>
            <div className={'flex gap-2'}>
              <button
                type="button"
                className="rounded border bg-white px-4 py-1 text-sm font-semibold hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onCancelAmendment}
              >
                Cancel
              </button>
              <button
                disabled={
                  localAmendment.original_clause_reference === undefined
                }
                type="button"
                className={`rounded border px-4 py-1 text-sm font-semibold  ${
                  localAmendment.original_clause_reference
                    ? 'border-indigo-600 bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'border-indigo-300 bg-indigo-300 text-gray-200'
                }`}
                onClick={onDocumentChangeSave}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <div>Select an amendment</div>
      )}
    </div>
  )
}

export default SupplementaryConditionsQAEditor
