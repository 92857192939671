import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { auth0TokenHelper } from '../../utils/auth0-token-helper'

interface AuthWrapperProps {
  children: React.ReactNode
}

// This component is a wrapper that ensures the user is authenticated before rendering the children.
const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    user,
    getAccessTokenSilently,
    error,
  } = useAuth0()
  const isLoggedIn = isAuthenticated && user
  const dispatch = useDispatch()

  auth0TokenHelper.setAccessTokenSilently(getAccessTokenSilently)

  useEffect(() => {
    if (!isLoading && !error) {
      if (!isAuthenticated) {
        loginWithRedirect()
      }
    }
  }, [dispatch, error, isAuthenticated, isLoading, loginWithRedirect])

  const renderErrorPage = useMemo(() => {
    console.log(error?.message)
    switch (error?.message) {
      case 'user is blocked':
        return (
          <div className="flex h-screen w-screen flex-col items-center justify-center">
            <h1 className="text-2xl font-bold">
              Your account has been blocked
            </h1>
            <div>
              If you think this is a mistake, please contact support at
              support@useprovision.com
            </div>
          </div>
        )
      default:
        return (
          <div className="flex h-screen w-screen flex-col items-center justify-center">
            <h1 className="text-2xl font-bold">Authentication Error</h1>
            <div>Please contact support at support@useprovision.com</div>
          </div>
        )
    }
  }, [error])

  return error ? renderErrorPage : isLoggedIn ? <div>{children}</div> : null
}

export default AuthWrapper
