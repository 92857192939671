import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { selectModal, setModal } from '../../redux/application-slice'
import { useUpdateProjectMutation } from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { toast } from 'react-toastify'

interface RenameProjectModalProps {
  open: boolean
}

function RenameProjectModal({ open }: RenameProjectModalProps) {
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const modal = useSelector(selectModal)
  const project = modal?.project
  const [projectName, setProjectName] = useState<string>()
  const [updateProject] = useUpdateProjectMutation()

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setSubmitting(false)
    setProjectName('')
  }, [dispatch])

  const onSubmit = useCallback(async () => {
    if (submitting) return

    setSubmitting(true)
    try {
      await updateProject({
        ...project,
        title: projectName,
      }).unwrap()
      posthog?.capture(POSTHOG.project_renamed, {
        project_uuid: project.uuid,
      })
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
      setSubmitting(false)
    }
    onClose()
  }, [onClose, posthog, project, projectName, submitting, updateProject])

  useEffect(() => {
    setProjectName(project?.title)
  }, [project])

  const onChange = useCallback((e) => {
    setProjectName(e.target.value)
  }, [])

  const onEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Project name
            </label>
            <div className="mt-1">
              <input
                value={projectName}
                onChange={onChange}
                onKeyDown={onEnterPress}
                tabIndex={0}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              onClick={onSubmit}
              type="submit"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
              Update
            </button>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [onChange, onEnterPress, onSubmit, projectName])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RenameProjectModal
