import React from 'react'
import { Project } from '../../shared/interfaces/project/project.interface'
import ProjectButton from '../file-manager/projects/project-button/project-button'
import { selectCurrentProject } from '../../redux/application-slice'
import { useSelector } from 'react-redux'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import SortableDocument from './sortable-document'

interface ProjectManagerDragDropProps {
  projects: Project[]
  filterQuery: string
  handleDrop: (dropResult: DragEndEvent) => void
}

const ProjectManagerDragDrop: React.FC<ProjectManagerDragDropProps> = ({
  filterQuery,
  projects,
  handleDrop,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 0, tolerance: 100 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDrop}
    >
      <SortableContext
        items={projects?.map((d) => d.uuid ?? '') ?? []}
        strategy={verticalListSortingStrategy}
      >
        {projects &&
          projects
            .filter((project) =>
              project?.title
                ?.toLocaleLowerCase()
                .includes(filterQuery.toLocaleLowerCase())
            )
            .map((project) => (
              <SortableDocument id={project.uuid ?? ''} key={project.uuid}>
                <div
                  className={`flex w-full items-center rounded text-gray-600 hover:bg-gray-100 hover:text-gray-900 ${currentProject?.id === project.id ? 'bg-gray-100 text-gray-900' : ''}`}
                >
                  <ProjectButton project={project} />
                </div>
              </SortableDocument>
            ))}
      </SortableContext>
    </DndContext>
  )
}
export default ProjectManagerDragDrop
