import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { apiSlice } from './api-slice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { applicationReducer } from './application-slice'
import { viewerReducer } from './viewer-slice'
import { editorReducer } from './editor-slice'
import { searchReducer } from './search-slice'
import { rtkQueryErrorLogger } from './error-middleware'
import { secondaryViewerReducer } from './secondary-viewer-slice'
import * as Sentry from '@sentry/react'
import { workflowSlice } from './workflow-slice'

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [workflowSlice.name]: workflowSlice.reducer,
  application: applicationReducer,
  viewer: viewerReducer,
  secondaryViewer: secondaryViewerReducer,
  editor: editorReducer,
  search: searchReducer,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: { warnAfter: 128 },
      })
        .concat(apiSlice.middleware)
        .concat([rtkQueryErrorLogger]),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers.concat(sentryReduxEnhancer)
    },
  })
}

export const store = setupStore()
export const getStore = (preloadedState?: PreloadedState<RootState>) =>
  setupStore(preloadedState)

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
