import React from 'react'
import DocumentViewer from './document-viewer/document-viewer'
import { ErrorBoundary } from '@sentry/react'
import ErrorMessage from './workflows/workflow-components/error-message'

interface DocumentSidebarProps {
  width: string | number
}

const DocumentSidebar: React.FC<DocumentSidebarProps> = ({ width }) => {
  return (
    <div
      className="h-full w-full"
      style={{
        width,
      }}
    >
      <ErrorBoundary fallback={ErrorMessage}>
        <DocumentViewer />
      </ErrorBoundary>
    </div>
  )
}

export default DocumentSidebar
