import { Popover } from '@headlessui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'

interface ExportPopoverProps {
  onClickExportToExcel?: () => void
  onClickExportToPDF?: () => void
  exportTypes: ('pdf' | 'excel')[]
  isDisabled?: boolean
  compact?: boolean
}

const ExportPopover: React.FC<ExportPopoverProps> = ({
  onClickExportToExcel,
  onClickExportToPDF,
  isDisabled = false,
  exportTypes,
  compact = false,
}) => {
  const [exportButtonRef, setExportButtonRef] =
    useState<HTMLButtonElement | null>(null)
  const [exportPanelRef, setExportPanelRef] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: exportStyles, attributes: exportAttributes } = usePopper(
    exportButtonRef,
    exportPanelRef
  )
  return (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button
        className={'rounded hover:bg-gray-200'}
        tabIndex={-1}
        ref={setExportButtonRef}
        disabled={isDisabled}
      >
        {compact ? (
          <div className={'p-1'}>
            <EllipsisVerticalIcon width={24} />
          </div>
        ) : (
          <div
            data-tooltip-id="export-questions-id"
            data-tooltip-content="Documents are still processing"
            className={`flex rounded px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${
              !isDisabled ? ' bg-white hover:bg-gray-100' : ' bg-gray-200 '
            }'}`}
          >
            <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
            Export
          </div>
        )}
      </Popover.Button>

      <Popover.Panel
        ref={setExportPanelRef}
        style={exportStyles.popper}
        {...exportAttributes.popper}
        className="z-50 rounded border bg-white shadow"
      >
        {exportTypes.includes('excel') && (
          <button
            className="w-36 px-4 py-2 text-left hover:bg-gray-100"
            onClick={onClickExportToExcel}
          >
            Export to Excel
          </button>
        )}
        {exportTypes.includes('pdf') && (
          <button
            className="w-36 px-4 py-2 text-left hover:bg-gray-100"
            onClick={onClickExportToPDF}
          >
            Export to PDF
          </button>
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default ExportPopover
