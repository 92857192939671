import React, { forwardRef, useMemo } from 'react'
import { DocumentSegment } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import { useCoords } from '../../hooks/use-coords'
import { useSelector } from 'react-redux'
import { selectSelectedAddendum } from '../../redux/viewer-slice'

interface PageOverlayAddendaProps {
  addendaSegment: DocumentSegment
  shouldElevate: boolean
}

const PageOverlayAddenda = forwardRef<
  HTMLButtonElement,
  PageOverlayAddendaProps
>(({ addendaSegment, shouldElevate }, ref) => {
  const { getCoords } = useCoords()
  const selectedAddenda = useSelector(selectSelectedAddendum)
  const coords = useMemo(() => {
    if (!addendaSegment?.quads?.length) {
      return {
        width: 0,
        height: 0,
        topOffset: 0,
        leftOffset: 0,
      }
    }
    const quad = addendaSegment.quads[0]
    return getCoords(
      addendaSegment.quads[0],
      addendaSegment.page,
      'document-viewer',
      quad.y3 > quad.y2
    )
  }, [addendaSegment.page, addendaSegment.quads, getCoords])
  return (
    <button
      ref={ref}
      className={`absolute ${selectedAddenda === addendaSegment?.id ? 'bg-yellow-300' : 'bg-yellow-100'} opacity-20 ${
        shouldElevate ? 'z-50' : 'z-30'
      }`}
      style={{
        width: `${coords.width}%`,
        height: `${coords.height}%`,
        top: `${coords.topOffset}%`,
        left: `${coords.leftOffset}px`,
      }}
    />
  )
})

PageOverlayAddenda.displayName = 'PageOverlayAddenda'
export default PageOverlayAddenda
