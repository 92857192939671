import React, { useCallback } from 'react'
import { ProvisionNotification } from '../../../shared/interfaces/notification/notification.interface'
import { Project } from '../../../shared/interfaces/project/project.interface'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

interface NotificationPopoverNotificationProps {
  notification: ProvisionNotification
  projects: Project[]
  onNavigateClick: () => void
}

const NotificationPopoverNotification: React.FC<
  NotificationPopoverNotificationProps
> = ({ notification, projects, onNavigateClick }) => {
  const navigate = useNavigate()
  const onNotificationClickLink = useCallback(() => {
    onNavigateClick()
    const project = projects?.find(
      (project) => project.id === notification.project
    )
    if (!project) {
      return
    }
    navigate(notification?.route ?? `/${project.uuid}/documents`)
  }, [
    projects,
    navigate,
    notification?.route,
    notification?.project,
    onNavigateClick,
  ])
  return (
    <button
      key={notification.id}
      onClick={onNotificationClickLink}
      className="flex w-96 min-w-0 items-center justify-between text-left hover:bg-gray-100"
    >
      <div className="px-4 py-4">
        <div
          key={notification.id}
          className="block w-80 overflow-hidden truncate whitespace-nowrap"
        >
          {notification.message ?? 'Click to View Notification'}
        </div>
        <div className="text-gray-700">
          {dayjs(notification.date_created).fromNow()}
        </div>
      </div>
      {!notification.is_read && (
        <div className="mr-2 h-[0.4rem] w-[0.4rem] rounded-full bg-red-500" />
      )}
    </button>
  )
}
export default NotificationPopoverNotification
