import { Popover } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectCurrentProject } from '../../../redux/application-slice'
import WorkflowButton from './workflow-button'
import { workflows } from './workflow.data'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useGetUserProfileQuery } from '../../../redux/api-slice'

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>
type IconProps = IconSVGProps & {
  title?: string
  titleId?: string
}

type Icon = React.FC<IconProps>

export interface Workflow {
  title: string
  Icon: Icon
  route: string
  featureFlag?: boolean
}

const WorkflowPopover: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null
  )
  const { pathname } = useLocation()
  const { data: user } = useGetUserProfileQuery(undefined)

  useEffect(() => {
    if (!pathname) {
      return
    }
    const workflowMatch = workflows.find((w) => pathname.includes(w.route))
    if (!workflowMatch) {
      return
    }
    setSelectedWorkflow(workflowMatch)
  }, [pathname])

  return (
    <Popover className={'text-sm text-gray-900'}>
      <Popover.Button
        disabled={!currentProject?.uuid}
        className={`relative flex items-center justify-between gap-2 rounded px-4 py-2 ${
          !currentProject?.uuid ? '' : 'hover:bg-gray-100'
        } `}
        tabIndex={-1}
        ref={setReferenceElement}
      >
        {selectedWorkflow && (
          <selectedWorkflow.Icon className="h-5 w-5 text-gray-400" />
        )}
        {selectedWorkflow
          ? selectedWorkflow.title
          : `Select a ${!currentProject?.uuid ? 'Project' : 'Workflow'}`}
        {currentProject?.uuid && (
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        )}
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col rounded border bg-white shadow"
      >
        {workflows
          .filter(
            (w) =>
              (w.featureFlag &&
                w.route === 'quality-assurance' &&
                user?.feature_flags?.editor_mode) ||
              !w.featureFlag
          )
          .map((workflow, index) => (
            <WorkflowButton
              key={`workflow_${workflow.title}`}
              workflow={workflow}
              setSelectedWorkflow={setSelectedWorkflow}
              referenceElement={referenceElement}
              index={index}
            />
          ))}
      </Popover.Panel>
    </Popover>
  )
}

export default WorkflowPopover
