import { RiskSort } from '../../components/workflows/risk-review/risk-review-sort-listbox'
import {
  ProjectRisk,
  ProjectRiskComment,
  ProjectRiskFeedback,
  RiskRanking,
  RiskStatus,
} from '../../shared/interfaces/project/risk/risk-inteface'
import { buildQueryParams } from '../../utils/build-query-params'
import { apiSlice } from '../api-slice'

export interface ProjectRiskStats {
  count: number
  risk_ranking: RiskRanking
}

export interface ProjectRiskStatusStats {
  count: number
  status?: RiskStatus
}

export interface RiskPipelineStats {
  count: number
}

export interface GetProjectRisks {
  projectUUID: string
  status?: RiskStatus
  sort?: string
}

export interface ProjectRiskUpdate {
  id: string
  status?: RiskStatus
  riskRanking?: RiskRanking
  projectUUID: string
  riskStatusFilter?: RiskStatus
  sort?: RiskSort
}

const projectRiskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectRisk: builder.query<ProjectRisk[], GetProjectRisks>({
      query: ({ projectUUID, status, sort }) => {
        const queryParams = buildQueryParams({
          status,
          project: projectUUID,
          sort,
        })
        return `/projectrisks/?${queryParams.toString()}`
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ProjectRisk' as const,
                id,
              })),
              { type: 'ProjectRisk' as const, id: 'LIST' },
            ]
          : [{ type: 'ProjectRisk' as const, id: 'LIST' }],
    }),
    getProjectRiskStats: builder.query<ProjectRiskStats[], string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        })
        return `/projectrisks/stats/?${queryParams.toString()}`
      },
      transformResponse: (response: ProjectRiskStats[]) => {
        return response.map((projectRiskStat) => {
          return {
            ...projectRiskStat,
            risk_ranking: parseInt(projectRiskStat.risk_ranking.toString()),
          }
        }, [])
      },
    }),
    getProjectRiskStatusStats: builder.query<ProjectRiskStatusStats[], string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        })
        return `/projectrisks/status_stats?${queryParams.toString()}`
      },
      transformResponse: (response: ProjectRiskStatusStats[]) => {
        return response.map((projectRiskStat) => {
          return {
            ...projectRiskStat,
            status: parseInt(projectRiskStat?.status?.toString() ?? ''),
          }
        }, [])
      },
    }),
    getProjectRisksProcessingStatus: builder.query<RiskPipelineStats, string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        })
        return `/projectrisks/stats_analysing/?${queryParams.toString()}`
      },
    }),
    updateProjectRisk: builder.mutation<ProjectRisk, ProjectRiskUpdate>({
      query: ({ id, status, riskRanking, projectUUID }) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        })
        return {
          url: `/projectrisks/${id}/?${queryParams.toString()}`,
          method: 'PATCH',
          body: {
            status: status,
            risk_ranking: riskRanking,
          },
        }
      },
      async onQueryStarted(
        { id, status, riskRanking, riskStatusFilter, projectUUID, sort },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            'getProjectRisk',
            {
              projectUUID: projectUUID,
              status: riskStatusFilter,
              sort,
            },
            (draft) => {
              const index = draft.findIndex(
                (projectRiskItr) => projectRiskItr.id === id
              )
              if (index !== -1) {
                if (status !== undefined) {
                  draft[index].status = status
                }
                if (riskRanking !== undefined) {
                  draft[index].risk_ranking = riskRanking
                }
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    createProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: (projectRiskComment) => ({
        url: '/projectriskcomments/',
        method: 'POST',
        body: projectRiskComment,
      }),
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            'getProjectRisk',
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: '' },
            (draft) => {
              const projectRisk = projectRiskComment.parent
              if (!projectRisk) {
                return
              }
              const index = draft.findIndex(
                (projectRiskItr) => projectRiskItr.id === projectRisk.id
              )
              if (index !== -1) {
                draft[index].comments?.push({
                  comment: projectRiskComment.comment,
                  date_created: new Date().toISOString(),
                  mentions: projectRiskComment.mentions,
                  user: projectRiskComment.user,
                })
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'ProjectRisk', id: 'LIST' }],
    }),
    submitProjectRiskFeedback: builder.mutation<
      ProjectRiskFeedback,
      ProjectRiskFeedback
    >({
      query: (projectRiskFeedback) => ({
        url: `/projectrisks/${projectRiskFeedback.project_risk}/feedback/`,
        method: 'POST',
        body: projectRiskFeedback,
      }),
    }),
    updateProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: (projectRiskComment) => ({
        url: `/projectriskcomments/${projectRiskComment.id}/`,
        method: 'PATCH',
        body: projectRiskComment,
      }),
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            'getProjectRisk',
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: '' },
            (draft) => {
              const projectRisk = projectRiskComment.parent
              if (!projectRisk) {
                return
              }
              const index = draft.findIndex(
                (projectRiskItr) => projectRiskItr.id === projectRisk.id
              )
              if (index === -1) {
                return
              }
              const commentsIndex = draft[index]?.comments?.findIndex(
                (c) => c.id === projectRiskComment.id
              )
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex
              if (cleanIndex === -1) {
                return
              }
              const comments = draft[index]?.comments
              if (!comments) {
                return
              }
              if (!comments[cleanIndex]) {
                return
              }
              comments[cleanIndex] = projectRiskComment
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'ProjectRisk', id: 'LIST' }],
    }),
    deleteProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: ({ id }) => ({
        url: `/projectriskcomments/${id}/`,
        method: 'DELETE',
      }),
      // optimistic update
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          projectRiskApi.util.updateQueryData(
            'getProjectRisk',
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: '' },
            (draft) => {
              const index = draft.findIndex(
                (projectRisk) =>
                  projectRisk.id === projectRiskComment.project_risk_id
              )
              const commentsIndex =
                projectRiskComment?.parent?.comments?.findIndex(
                  (rc) => rc?.id === projectRiskComment?.id
                )
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex
              if (cleanIndex === -1) {
                return
              }
              if (cleanIndex !== -1 && projectRiskComment?.parent?.comments) {
                if ((draft[index]?.comments?.length ?? 0) > 1) {
                  draft[index]?.comments?.splice(cleanIndex, 1)
                } else {
                  draft[index].comments = []
                }
              }
              draft[index] = { ...draft[index] }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          deleteResult.undo()
        }
      },
      invalidatesTags: (result, error, projectRiskComment) => [
        { type: 'ProjectRisk', id: projectRiskComment.id },
      ],
    }),
  }),
})

export const {
  useGetProjectRiskQuery,
  useGetProjectRiskStatsQuery,
  useGetProjectRisksProcessingStatusQuery,
  useCreateProjectRiskCommentMutation,
  useUpdateProjectRiskCommentMutation,
  useDeleteProjectRiskCommentMutation,
  useUpdateProjectRiskMutation,
  useSubmitProjectRiskFeedbackMutation,
  useGetProjectRiskStatusStatsQuery,
} = projectRiskApi
