import React from 'react'
import DocumentList from '../document-list'

function ProjectHome() {
  return (
    <div
      id="documents-section"
      className="relative flex flex-col overflow-hidden grow"
    >
      <DocumentList />
    </div>
  )
}

export default ProjectHome
