import { FilePondFile } from 'filepond'
import React, { useCallback } from 'react'
import { FileProgress } from './document-view-drag-and-drop'
import { XMarkIcon } from '@heroicons/react/24/outline'
interface FileUploadPreviewProps {
  file: FilePondFile
  fileProgressRef: FileProgress
  onRemove: (file: FilePondFile) => void
}

const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  file,
  fileProgressRef,
  onRemove,
}) => {
  const calculateProgressWidth = useCallback(
    (file: FilePondFile) => {
      const loaded = fileProgressRef?.[file.id]?.loaded ?? 0
      const total = fileProgressRef?.[file.id]?.total ?? 0
      if (!total) {
        return '0%'
      }
      const width = `${(loaded / total) * 100}%`
      return width
    },
    [fileProgressRef]
  )

  const onClickRemove = useCallback(() => {
    onRemove(file)
  }, [file, onRemove])
  return (
    <div className="flex justify-between text-xs" key={file.id}>
      <div>
        <div>{file.filename}</div>
        <div
          className={`w-0 rounded-full bg-blue-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100 transition-all ${
            fileProgressRef?.[file.id]?.loaded ? 'animate-pulse' : ''
          }`}
          style={{
            width: calculateProgressWidth(file),
          }}
        />
      </div>
      <button onClick={onClickRemove}>
        <XMarkIcon className="h-4 w-4" />
      </button>
    </div>
  )
}
export default FileUploadPreview
