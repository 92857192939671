import { apiSlice } from '../api-slice'

interface Organisation {
  id: string
  name: string
}

interface OrganisationUser {
  first_name: string
  last_name: string
  email: string
  roles: string[]
}

interface AddRemoveOrganisationMember {
  email: string
  organisation_id: string | number
}

interface UpdateMemberRoles {
  email: string
  roles: string[]
  organisation_id: string | number
}

const organisationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisation: builder.query<Organisation, string>({
      query: (id) => `/organisations/${id}/`,
    }),
    getOrganisationMembers: builder.query<OrganisationUser[], string>({
      query: (id) => `/organisations/${id}/members/`,
      providesTags: [{ type: 'OrganisationMember', id: 'LIST' }],
    }),
    addOrganisationMember: builder.mutation<
      OrganisationUser,
      AddRemoveOrganisationMember
    >({
      query: ({ organisation_id, email }) => ({
        url: `/organisations/${organisation_id}/members/add/`,
        method: 'POST',
        body: { email: email },
      }),
    }),
    removeOrganisationMember: builder.mutation<
      OrganisationUser,
      AddRemoveOrganisationMember
    >({
      query: ({ organisation_id, email }) => ({
        url: `/organisations/${organisation_id}/members/remove/`,
        method: 'POST',
        body: { email: email },
      }),
    }),
    updateOrganisationMemberRoles: builder.mutation<
      OrganisationUser,
      UpdateMemberRoles
    >({
      query: ({ organisation_id, email, roles }) => ({
        url: `/organisations/${organisation_id}/members/update_roles/`,
        method: 'POST',
        body: { email: email, roles: roles },
      }),
      invalidatesTags: [{ type: 'OrganisationMember', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetOrganisationQuery,
  useGetOrganisationMembersQuery,
  useAddOrganisationMemberMutation,
  useRemoveOrganisationMemberMutation,
  useUpdateOrganisationMemberRolesMutation,
} = organisationApi
