import Root from './root'
import React from 'react'
import CommentTablePage from '../components/workflows/comment-table/comment-table-page'
import * as Sentry from '@sentry/react'
import Filtering from '../components/workflows/filtering/filtering'
import DocumentChangePage from '../components/workflows/document-change/document-change-page'
import CommentViewer from '../components/comment-viewer/comment-viewer'
import NotificationCenter from '../components/notification/notification-center'
import Home from '../components/home'
import { RouteObject } from 'react-router-dom'
import ProjectHome from '../components/file-manager/projects/project-home'
import AiChat from '../components/sidebar/ai-chat/ai-chat'
import RiskReviewPage from '../components/workflows/risk-review/risk-review-page'
import QualityAssuranceWorkflow from '../components/workflows/quality-assurance/quality-assurance-workflow'
import NotFoundPage from '../components/404page/not-found-page'
import SettingsHome from '../components/settings/settings-home'

const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <Sentry.ErrorBoundary showDialog />,
    children: [
      {
        path: '',
        element: <Home />,
        children: [
          {
            path: ':projectId/chat/:documentId?',
            element: <AiChat />,
          },
          {
            path: ':projectId/documents/:documentId?',
            element: <ProjectHome />,
          },
          {
            path: ':projectId',
            element: <ProjectHome />,
          },
          {
            path: ':projectId/comments/:documentId?',
            element: <CommentTablePage />,
          },
          {
            path: ':projectId/clause-filters/:documentId?',
            element: <Filtering />,
          },
          {
            path: ':projectId/supplementary-conditions/:documentId?',
            element: <DocumentChangePage />,
          },
          {
            path: ':projectId/riskreview/:documentId?',
            element: <RiskReviewPage />,
          },
          {
            path: ':projectId/quality-assurance/:documentId?',
            element: <QualityAssuranceWorkflow />,
          },
        ],
      },
      {
        path: ':projectId/commentviewer',
        element: <CommentViewer />,
      },
      {
        path: '/notifications',
        element: <NotificationCenter />,
      },
      {
        path: '/settings',
        element: <SettingsHome />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/404',
        element: <NotFoundPage />,
      },
    ],
  },
]

export default routesConfig
