import { Popover } from '@headlessui/react'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import {
  selectShowCustomLabels,
  selectShowDefinitions,
  selectShowRevisionHighlights,
  setShowCustomLabels,
  setShowDefinitions,
  setShowRevisionHighlights,
} from '../../../redux/viewer-slice'
import { useDispatch, useSelector } from 'react-redux'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

interface DocumentViewerViewOptionsProps {
  // Define your component props here
}

const DocumentViewerViewOptions: React.FC<DocumentViewerViewOptionsProps> = (
  props
) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const showRevisionHighlights = useSelector(selectShowRevisionHighlights)
  const showCustomLabels = useSelector(selectShowCustomLabels)
  const showDefinitions = useSelector(selectShowDefinitions)
  const dispatch = useDispatch()

  const onToggleShowRevisionHighlights = () => {
    dispatch(setShowRevisionHighlights(!showRevisionHighlights))
  }

  const onToggleShowCustomLabels = () => {
    dispatch(setShowCustomLabels(!showCustomLabels))
  }

  const onToggleShowDefinitions = () => {
    dispatch(setShowDefinitions(!showDefinitions))
  }

  return (
    <div>
      <Popover>
        <Popover.Button
          className={'rounded bg-gray-100 p-1 shadow-sm hover:bg-gray-200'}
          tabIndex={-1}
          ref={setReferenceElement}
        >
          View
        </Popover.Button>
        <Popover.Panel
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 flex flex-col rounded border bg-white shadow"
        >
          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowRevisionHighlights}
          >
            {showRevisionHighlights ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showRevisionHighlights ? 'Hide' : 'Show'} comment/revision
            highlights
          </button>

          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowCustomLabels}
          >
            {showCustomLabels ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showCustomLabels ? 'Hide' : 'Show'} labels/tags
          </button>
          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowDefinitions}
          >
            {showDefinitions ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showDefinitions ? 'Hide' : 'Show'} definitions
          </button>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default DocumentViewerViewOptions
