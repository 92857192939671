import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { WebsocketContext } from '../../../contexts/websocket-context'
import { ChatSessionMessage } from '../../../shared/interfaces/chat/chat-history.interface'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'

interface AIChatConflictProps {
  chatSessionMessage: ChatSessionMessage
  query: string
}

const AIChatConflict: React.FC<AIChatConflictProps> = ({
  chatSessionMessage,
  query,
}) => {
  const websocketContext = useContext(WebsocketContext)
  const { conflicts, onCheckConflicts, conflictsThinking, conflictsWriting } =
    websocketContext
  const [chatSessionMessageID, setChatSessionMessageID] = useState<
    string | null
  >(null)
  const [conflictExpanded, setConflictExpanded] = useState(false)
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)

  useEffect(() => {
    if (!chatSessionMessage?.id) {
      return
    }
    setChatSessionMessageID(chatSessionMessage?.id)
  }, [chatSessionMessage?.id])

  useEffect(() => {
    if (!chatSessionMessageID || chatSessionMessage?.conflict) {
      return
    }
    if (conflicts[chatSessionMessageID]) {
      return
    }
    onCheckConflicts(query, chatSessionMessageID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSessionMessageID])

  const conflictMessage = useMemo(() => {
    if (chatSessionMessage?.conflict) {
      return chatSessionMessage?.conflict.message
    }
    if (!chatSessionMessageID) {
      return null
    }
    const conflictMessages = conflicts[chatSessionMessageID]
    if (conflictMessages) {
      return conflictMessages.content
    }
    return null
  }, [conflicts, chatSessionMessage?.conflict, chatSessionMessageID])

  const conflictAnswer = useMemo(() => {
    if (!conflictMessage) {
      return null
    }
    const paragarphs = conflictMessage.split('\n')
    if (paragarphs.length === 0) {
      return null
    }
    const firstParagraph = paragarphs[0]
    if (firstParagraph.toLocaleLowerCase().includes('yes')) {
      return 'There may be conflicts present'
    } else if (firstParagraph.toLocaleLowerCase().includes('no')) {
      return 'No conflicting information detected'
    }
    return firstParagraph
  }, [conflictMessage])

  const onClickExpandConflictToggle = useCallback(() => {
    setConflictExpanded((s) => !s)
    posthog?.capture(POSTHOG.document_chat_conflicts_opened, {
      project_uuid: currentProject?.uuid,
      chat_session_message_id: chatSessionMessageID,
    })
  }, [posthog, currentProject?.uuid, chatSessionMessageID])

  return chatSessionMessageID ? (
    <div className="shadow-xs mt-0.5 flex w-full justify-end text-xs ">
      <div className="w-full rounded bg-gray-100 p-1">
        {conflictsThinking[chatSessionMessageID] ||
        conflictsWriting[chatSessionMessageID] ? (
          <div className="animate-pulse text-left">
            Checking for conflicts...
          </div>
        ) : null}
        {!conflictsWriting[chatSessionMessageID] &&
        !conflictsThinking[chatSessionMessageID] ? (
          <button
            className="h-full w-full text-left"
            onClick={onClickExpandConflictToggle}
          >
            {conflictExpanded ? (
              conflictMessage ? (
                <div className="whitespace-pre-line">{conflictMessage}</div>
              ) : null
            ) : (
              <div className="flex">
                {conflictAnswer} <ChevronDownIcon className="h-4 w-4" />
              </div>
            )}
          </button>
        ) : null}
      </div>
    </div>
  ) : null
}

export default AIChatConflict
